import { ConfigActionType } from './types';

export const ActionCreators = {
    setUnknownStatus: (status) => {
        return ({
            payload: status,
            type: ConfigActionType.SET_NOT_READY,
        });
    },
    setReadyStatus: (status) => {
        return ({
            payload: status,
            type: ConfigActionType.SET_READY,
        });
    },
    toggleSettingsMenu: () => {
        return ({
            type: ConfigActionType.TOGGLE_SETTINGS
        });
    }
};
