import { BalanceActionType } from './types';

export const actionCreators = {
    update: (token) => async (dispatch) => {
        const payload = await actionCreators.fetchBalanceUnwrapped(token);
        if (payload) {
            dispatch({
                type: BalanceActionType.REQUEST_SUCCESS,
                payload
            });
        } else {
            dispatch({ type: BalanceActionType.REQUEST_FAIL });
        }
    }, 
    fetchBalanceUnwrapped: async (token) => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            }
        };
        try {
            const response = await fetch(`api/v1/players/0/balance`, requestOptions);
            if (response.ok) {
                const payload = await response.json();
                return payload;
            }

            else if (response.status === 401) {
                window.location.reload();
            }
        } catch { }
        return null;
    },
    fetchOpenBetsUnwrapped: async (token) => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            }
        };
        try {
            const response = await fetch(`api/v1/players/0/openbets`, requestOptions);
            if (response.ok) {
                const payload = await response.json();
                return payload;
            }

            else if (response.status === 401) {
                window.location.reload();
            }
        } catch { }
        return null;
    },
    fetchReadbackUnwrapped: async (token, ids) => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            }
        };
        try {
            const response = await fetch(`api/v1/players/${ids}/readback`, requestOptions);
            if (response.ok) {
                const payload = await response.json();
                return payload;
            }

            else if (response.status === 401) {
                window.location.reload();
            }
        } catch { }
        return null;
    }
};
