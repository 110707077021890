import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import withRouter from '../hooks/withRouter';
import { actionCreators as AuthActions } from '../store/auth/actions';
import { actionCreators as BalanceActions } from '../store/balance/actions';
import { actionCreators as BetslipActions } from '../store/betslip/actions';
import { ActionCreators as ConfigActions } from '../store/config/actions';
import Colors from '../containers/Colors';
import Language from '../containers/Language';
import ChooseSkin from '../containers/ChooseSkin';
import { Base64 } from '../store/auth/types';
import { injectIntl } from 'react-intl';

class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);
        this.state = {
            matches: window.matchMedia("(min-width: 768px)").matches,
            matches2: window.matchMedia("(max-width: 991.98px)").matches,
            dropdownOpen: false,
            accountDropDownOpen: false,
            isColorsModalOpen: false,
            isLanguageModalOpen: false,
            isChooseSkinOpen: false,
            showIframe: false,
            selectedKey: null,
            iframeSrc: '',
            openItems: []
        };
        this.didMount = false;
        this.toggleDropDown = this.toggleDropDown.bind(this);
        this.toggleAccountDropDown = this.toggleAccountDropDown.bind(this);
    }

    toggleItems = (id) => {
        const { openItems } = this.state;
        const index = openItems.indexOf(id);
        const nextOpenItems = index < 0
            ? [...openItems, id]
            : [];
        this.setState({ openItems: nextOpenItems });
    }

    handleItemClick = (path, opensInIframe) => {
        if (opensInIframe) {
            this.handleIframeButtonClick(path);
        }
        this.setState({ openItems: [] });
    }

    handleIframeButtonClick = (url) => {
        this.setState(prevState => ({
            showIframe: !prevState.showIframe
        }));
        this.setState({ selectedKey: url });
        this.setState({ iframeSrc: url });
    };


    toggleColorsModal = () => {
        this.setState(prevState => ({
            isColorsModalOpen: !prevState.isColorsModalOpen
        }));
    }

    toggleLanguageModal = () => {
        this.setState(prevState => ({
            isLanguageModalOpen: !prevState.isLanguageModalOpen
        }));
    }

    toggleChooseSkinModal = () => {
        this.setState(prevState => ({
            isChooseSkinOpen: !prevState.isChooseSkinOpen
        }));
    }


    handleDropdownItemClick = () => {
        this.setState({ accountDropdownOpen: false });
    };

    toggleAccountDropDown() {
        this.setState(prevState => ({
            accountDropdownOpen: !prevState.accountDropdownOpen
        }));

    }

    handleCloseColors = () => {
        this.setState({ isColorsModalOpen: false });
    };

    handleCloseLanguage = () => {
        this.setState({ isLanguageModalOpen: false });
    };

    handleCloseChooseSkin = () => {
        this.setState({ isChooseSkinOpen: false });
    }

    setMatchMedia = (e) => {
        if (this.didMount) {
            this.setState({ matches: e.matches });
        }
    };

    setMatchMedia2 = (e) => {
        if (this.didMount) {
            this.setState({ matches2: e.matches });
        }
    };


    signout = () => {
        this.props.logout();
    };

    toggleDropDown() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    componentDidMount() {
        this.didMount = true;
        window.matchMedia("(min-width: 768px)").addEventListener('change', this.setMatchMedia);
        window.matchMedia("(max-width: 991.98px)").addEventListener('change', this.setMatchMedia2);
        this.props.updateBalance(this.props.token);
    }

    componentWillUnmount() {
        this.didMount = false;
        window.matchMedia("(min-width: 768px)").removeEventListener('change', this.setMatchMedia);
        window.matchMedia("(max-width: 991.98px)").removeEventListener('change', this.setMatchMedia2);
    }

    buildMobileBottomNavbar(selectedKey) {
        const reports = this.props.intl.formatMessage({ id: 'reports' });
        const betslip = this.props.intl.formatMessage({ id: 'betslip' })
        const sports = this.props.intl.formatMessage({ id: "sports" });
        const { formatMessage } = this.props.intl;
        const domain = window.location.hostname;
        const currentConfig = this.props.context.configurations.find(config => config.url === domain);
        const configButtons = currentConfig && currentConfig.buttons ? currentConfig.buttons.map(button => ({
            label: button.name,
            path: button.url,
            icon: 'home', 
            opensInIframe: button.opensInIframe
        })) : [];

        return (
            <nav className="mobile-bottom-nav">
                {(selectedKey === '/') &&
                    <Link onClick={() => this.props.toggleSportMenu()} className='mobile-bottom-nav__item'>
                        <div className="mobile-bottom-nav__item-content">
                            <i className="material-icons">menu</i>
                            {sports}
                        </div>
                    </Link>
                }
                {!(selectedKey === '/') &&
                    <Link to='/' className="mobile-bottom-nav__item">
                        <div className="mobile-bottom-nav__item-content">
                            <i className="material-icons">menu</i>
                            Menu
                        </div>
                    </Link>
                }
                <Link onClick={this.props.toggleBetslip} className={'mobile-bottom-nav__item position-relative' + (selectedKey === '/account' ? 'mobile-bottom-nav__item--active' : '')}>
                    <div className="mobile-bottom-nav__item-content">
                        <i className="material-icons">shopping_cart</i>
                        {betslip}
                        {(this.props.cartItemCount > 0) &&
                            <span className="betslip badge translate-middle p-2 border border-light rounded-circle">
                                {this.props.cartItemCount}
                                <span className="visually-hidden">unread messages</span>
                            </span>
                        }
                    </div>
                </Link>
                <Link to='/account' className={'mobile-bottom-nav__item ' + (selectedKey === '/account' ? 'mobile-bottom-nav__item--active' : '')}>
                    <div className="mobile-bottom-nav__item-content">
                        <i className="material-icons">account_circle</i>
                        {reports}
                    </div>
                </Link>
                {configButtons.map((product, index) => (
                    <li key={index} className="mobile-bottom-nav__item ">
                        {product.opensInIframe ? (
                            <Link to={`/custom-window?t=${Base64.encode(product.path)}`}>
                                <div className="mobile-bottom-nav__item-content">
                                    <i className="material-icons">rss_feed</i>
                                    {formatMessage({ id: product.label })}
                                </div>
                            </Link>
                        ) : (
                            <Link to={product.path} className="mobile-bottom-nav__item" onClick={() => this.handleItemClick(product.path, false)}>
                                    <div className="mobile-bottom-nav__item-content">
                                        <i className="material-icons">rss_feed</i>
                                    {formatMessage({ id: product.label })}
                                </div>
                            </Link>
                        )}
                    </li>
                ))}


            </nav>
        );
    }

    buildSmallScreenNavbar(selectedKey) {
        const domain = window.location.hostname;
        const currentConfig = this.props.context.configurations.find(config => config.url === domain);
        const logo = currentConfig?.logos?.navBarLogo || null;
        const balance = this.props.intl.formatMessage({ id: 'balance' });
        const pending = this.props.intl.formatMessage({ id: 'pending' });

        return (
            <header className="app-bar">
                <div className="tool-bar">
                    <div className="tool-bar-container">
                        <div className="west">
                            <Link to='/' className="navbar-brand">
                                {logo && <img src={logo} alt="Logo" className="logo" />}
                            </Link>
                        </div>
                        <div className="middle">{this.props.info.userName}</div>
                        <div className="est">
                            <Link to='/account/settled' className="nav-link">
                                <span className="fw-300 fs-xs d-block opacity-50">
                                    <small>{balance}</small>
                                </span>
                                <span className="fw-500 fs-xl d-block text-end">
                                    {this.props.info.currencySymbol}{this.props.balance.real.toLocaleString()}
                                </span>
                            </Link>
                            <span role="separator" className="divider-vertical-second-menu"></span>
                            <Link to='/account/unsettled' className="nav-link">
                                <span className="fw-300 fs-xs d-block opacity-50">
                                    <small>{pending}</small>
                                </span>
                                <span className="fw-500 fs-xl d-block text-end">
                                    {this.props.info.currencySymbol}{this.props.balance.atRisk.toLocaleString()}
                                </span>
                            </Link>
                            <button type="button" className="btn btn-sm btn-outline-success position-relative ms-2" onClick={this.props.toggleSettingsMenu}>
                                <i className="material-icons">settings</i>
                            </button>
                            <a className="nav-link ms-2" onClick={this.signout}>
                                <i className="material-icons">logout</i>
                            </a>
                        </div>
                    </div>
                </div>
            </header>
        );
    }

    buildBigScreenNavbar(selectedKey, allowedProducts) {
        const domain = window.location.hostname;
        const currentConfig = this.props.context.configurations.find(config => config.url === domain);
        const logo = currentConfig?.logos?.navBarLogo || null;
        const balance = this.props.intl.formatMessage({ id: 'balance' });
        const pending = this.props.intl.formatMessage({ id: 'pending' });
        const logout = this.props.intl.formatMessage({ id: 'logout' });
        const { formatMessage } = this.props.intl;
        const configButtons = currentConfig && currentConfig.buttons ? currentConfig.buttons.map(button => ({
            label: button.name,
            path: button.url,
            icon: 'home', 
            opensInIframe: button.opensInIframe
        })) : [];

        const products = [
            ...allowedProducts,
        ];

        return (
            <header className="app-bar">
                <div className="tool-bar">
                    <div className="tool-bar-container">
                        <div className="west">
                            <Link to='/' className="navbar-brand">
                                {logo && <img src={logo} alt="Logo" className="logo" />}
                            </Link>
                            <ul className="nav nav-header">
                                {products.map((product, index) => (
                                    <li className={'nav-item ' + (selectedKey === product.path ? 'active' : '')} key={index}>
                                        {product.path ? (
                                            product.opensInIframe ? (
                                                <Link to="/live-betting" className="text-decoration-none py-0 nav-link" onClick={() => this.handleItemClick('/live-betting', true)}>
                                                    {product.label}
                                                </Link>
                                            ) : (
                                                <Link to={product.path} className="nav-link">
                                                    {product.label}
                                                </Link>
                                            )
                                        ) : (
                                            <button onClick={product.onClick} className="nav-link">
                                                {product.label}
                                            </button>
                                        )}
                                    </li>
                                ))}

                                {configButtons.map((button, index) => (
                                    <li key={index} className="nav-item">
                                        {button.opensInIframe ? (
                                            <Link to={`/custom-window?t=${Base64.encode(button.path)}`} className="nav-link">
                                                {formatMessage({ id: button.label })}
                                            </Link>
                                        ) : (
                                            <Link to={button.path} className="nav-link" onClick={() => this.handleItemClick(button.path, false)}>
                                                {formatMessage({ id: button.label })}
                                            </Link>
                                        )}
                                    </li>
                                ))}
                            </ul>

                        </div>
                        <div className="middle">{this.props.info.userName}</div>
                        <div className="est">
                            <Link to='/account/settled' className="nav-link">
                                <span className="fw-300 fs-xs d-block opacity-50">
                                    <small>{balance}</small>
                                </span>
                                <span className="fw-500 fs-xl d-block text-end">
                                    {this.props.info.currencySymbol}{this.props.balance.real.toLocaleString()}
                                </span>
                            </Link>
                            <span role="separator" className="divider-vertical-second-menu"></span>
                            <Link to='/account/unsettled' className="nav-link">
                                <span className="fw-300 fs-xs d-block opacity-50">
                                    <small>{pending}</small>
                                </span>
                                <span className="fw-500 fs-xl d-block text-end">
                                    {this.props.info.currencySymbol}{this.props.balance.atRisk.toLocaleString()}
                                </span>
                            </Link>
                            <span role="separator" className="divider-vertical-second-menu"></span>
                            <button type="button" className="btn btn-sm btn-outline-success position-relative ms-2" onClick={this.props.toggleSettingsMenu}>
                                <i className="material-icons">settings</i>
                            </button>
                            <button type="button" className="btn btn-sm btn-outline-primary ms-2" onClick={this.signout}>{logout}</button>
                        </div>
                    </div>
                </div>
            </header>


        );
    }

    render() {
        const { matches2 } = this.state;
        const selectedKey = this.props.location.pathname;
        const sports = this.props.intl.formatMessage({ id: 'sports' });
        const reports = this.props.intl.formatMessage({ id: 'reports' });
        //const live = this.props.intl.formatMessage({ id: 'live' });
        const betslip = this.props.intl.formatMessage({ id: 'betslip' });

        const allowedProducts = [
            matches2 && {
                label: <i className="fas fa-arrow-left"></i>,
                icon: 'home',
                onClick: () => this.props.toggleSportMenu()
            },
            matches2 && {
                label: betslip,
                icon: 'home',
                onClick: () => this.props.toggleBetslip()
            },
            {
                label: sports,
                path: '/',
                icon: 'home'
            },
            {
                label: reports,
                path: '/account',
                icon: 'account_circle'
            }/*,
            {
                label: live,
                path: '/live-betting',
                icon: 'rss_feed'
            }*/
        ].filter(Boolean);

        return (
            <>
                {this.state.matches && this.buildBigScreenNavbar(selectedKey, allowedProducts)}
                {!this.state.matches && this.buildSmallScreenNavbar(selectedKey)}
                {!this.state.matches && this.buildMobileBottomNavbar(selectedKey)}
                {this.state.isColorsModalOpen && <Colors onClose={this.handleCloseColors} />}
                {this.state.isLanguageModalOpen && <Language onClose={this.handleCloseLanguage} />}
                {this.state.isChooseSkinOpen && <ChooseSkin onClose={this.handleCloseChooseSkin} />}
                {this.state.showIframe && <iframe src={this.state.iframeSrc} width="100%" height="500px"></iframe>}
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        info: state.auth,
        token: state.auth.token,
        balance: state.balance,
        cartItemCount: (state.betslip.ids.length + (state.betslip.wager ? (state.betslip.wager.items.filter(b => b.idGame).length) : 0)),
        context: state.config
    };
}

const mapDispatchToProps = {
    logout: AuthActions.resetState,
    updateBalance: BalanceActions.update,
    toggleBetslip: BetslipActions.toggleBetslip,
    toggleSportMenu: BetslipActions.toggleSportMenu,
    toggleSettingsMenu: ConfigActions.toggleSettingsMenu,
    toggleColorsModal: () => ({ type: 'TOGGLE_COLORS_MODAL' }),
    toggleLanguageModal: () => ({ type: 'TOGGLE_LANGUAGE_MODAL' }),
    toggleChooseSkinModal: () => ({ type: 'TOGGLE_CHOOSESKIN_MODAL' })
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(NavMenu)));