import { BalanceActionType } from './types';

const initialState = {
    current: 0,
    available: 0,
    atRisk: 0,
    real: 0,
    credit: 0,
    freePlay: 0,
    thisWeek: 0,
    lastWeek: 0,
    bonus: 0,
    currency: 'USD'
};

const BalanceReducer = (state = initialState, action) => {
    switch (action.type) {
        case BalanceActionType.REQUEST_SUCCESS: {
            const balance = action.payload;

            return {
                ...balance
            };
        }
        case BalanceActionType.REQUEST_FAIL: {
            return {
                ...initialState
            };
        }
        default:
            return state;
    }
};

export default BalanceReducer;
