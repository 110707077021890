import React, { Component } from 'react';
import { connect } from 'react-redux';

class LiveBetting extends Component {
    static displayName = LiveBetting.name;

    constructor(props) {
        super(props);
        this.state = {
            contentHeight: '100px',
            url: '',
            loading: true
        };
        this.didMount = false;
    }

    handleResize = (e) => {
        if (this.didMount) {
            this.setState({ contentHeight: (window.innerHeight - 48) + 'px' });
        }
    };

    componentDidMount() {
        this.didMount = true;
        window.addEventListener('resize', this.handleResize);
        this.loadBettingContext();
        this.handleResize();
    }

    componentWillUnmount = () => {
        this.didMount = false;
        window.removeEventListener('resize', this.handleResize);
    };

    render() {

        if (this.state.loading)
            return <p>Loading...</p>

        return (
            <iframe
                src={this.state.url}
                height={this.state.contentHeight}
                width="100%"
                allowFullScreen="1"
                frameBorder="0"
                scrolling="auto">
            </iframe>
        );
    }

    async loadBettingContext() {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `bearer ${this.props.token}`
            }
        };

        const response = await fetch(`api/v1/players/1/livebetting`, requestOptions);
        if (response.ok) {
            const data = await response.json();
            this.setState({ url: data.message, loading: false });
        } else {
            if (response.status === 401) {
                this.props.logout();
            }
            this.setState({ loading: false });
        }
    }
}

function mapStateToProps(state) {
    return {
        token: state.auth.token,
        userName: state.auth.userName,
        balance: state.balance
    };
}

export default connect(mapStateToProps, undefined)(LiveBetting);