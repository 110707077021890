import { combineReducers } from "redux";
import ConfigReducer from "./config/reducer";
import AuthReducer from "./auth/reducer";
import BalanceReducer from "./balance/reducer";
import BetslipReducer from "./betslip/reducer";

export default combineReducers({
    config: ConfigReducer,
    auth: AuthReducer,
    balance: BalanceReducer,
    betslip: BetslipReducer
});