import { BetslipActionType, BetStatusEnum } from './types';
import {
    getRiskAndWin,
    PLAY_TYPE_VISITOR_SPREAD,
    PLAY_TYPE_HOME_SPREAD,
    PLAY_TYPE_TOTAL_OVER,
    PLAY_TYPE_TOTAL_UNDER
} from '../../helpers/betslip';

const initialState = {
    ids: [],
    byId: {},
    betType: 0,
    betStyle: 0,
    amountType: 0,
    amount: 0,
    minWager: 0,
    maxWager: 0,
    singleStake: 0,
    betslipOpen: false,
    sportMenuOpen: true,
    readbackOpen: false,
    betAccepted: null,
    betFailed: null,
    quickBetNotifications: {},
    acceptPriceChanges: 1, //  0  ask , 1 - higher , 2 - any, -1 - superbet
    status: BetStatusEnum.NONE,
    errorMsg: '',
    ticketId: '',
    teaserOpts: [],
    teaserId: 0,
    leagueIds: '',
    games: [],
    gameById: {},
    readback: null,
    useFreePlay: false,
    openSlots: 0,
    slotId: 0,
    wager: undefined
};

const compareAndSetStyles = (newState, prevState, betById) => {
    if (newState && prevState) {
        //OU Market
        if (newState.ou === 0) {
            newState.omStyle = 'lock';
            newState.umStyle = 'lock';

            if (betById[`${newState.id}_${PLAY_TYPE_TOTAL_OVER}`]) {
                betById[`${newState.id}_${PLAY_TYPE_TOTAL_OVER}`].errorMsg = 'GAMELINECHANGE';
                betById[`${newState.id}_${PLAY_TYPE_TOTAL_OVER}`].currentBase = 0;
                betById[`${newState.id}_${PLAY_TYPE_TOTAL_OVER}`].currentPrice = 0;
            }

            if (betById[`${newState.id}_${PLAY_TYPE_TOTAL_UNDER}`]) {
                betById[`${newState.id}_${PLAY_TYPE_TOTAL_UNDER}`].errorMsg = 'GAMELINECHANGE';
                betById[`${newState.id}_${PLAY_TYPE_TOTAL_UNDER}`].currentBase = 0;
                betById[`${newState.id}_${PLAY_TYPE_TOTAL_UNDER}`].currentPrice = 0;
            }
        } else if (newState.ou > prevState.ou) {
            newState.omStyle = 'down';
            newState.umStyle = 'up';

            if (betById[`${newState.id}_${PLAY_TYPE_TOTAL_OVER}`]) {
                betById[`${newState.id}_${PLAY_TYPE_TOTAL_OVER}`].errorMsg = 'GAMELINECHANGE';
                betById[`${newState.id}_${PLAY_TYPE_TOTAL_OVER}`].currentBase = newState.ou;
                betById[`${newState.id}_${PLAY_TYPE_TOTAL_OVER}`].currentPrice = newState.om;
            }

            if (betById[`${newState.id}_${PLAY_TYPE_TOTAL_UNDER}`]) {
                betById[`${newState.id}_${PLAY_TYPE_TOTAL_UNDER}`].errorMsg = 'GAMELINECHANGE';
                betById[`${newState.id}_${PLAY_TYPE_TOTAL_UNDER}`].currentBase = newState.ou;
                betById[`${newState.id}_${PLAY_TYPE_TOTAL_UNDER}`].currentPrice = newState.um;
            }
        } else if (newState.ou < prevState.ou) {
            newState.omStyle = 'up';
            newState.umStyle = 'down';

            if (betById[`${newState.id}_${PLAY_TYPE_TOTAL_OVER}`]) {
                betById[`${newState.id}_${PLAY_TYPE_TOTAL_OVER}`].errorMsg = 'GAMELINECHANGE';
                betById[`${newState.id}_${PLAY_TYPE_TOTAL_OVER}`].currentBase = newState.ou;
                betById[`${newState.id}_${PLAY_TYPE_TOTAL_OVER}`].currentPrice = newState.om;
            }

            if (betById[`${newState.id}_${PLAY_TYPE_TOTAL_UNDER}`]) {
                betById[`${newState.id}_${PLAY_TYPE_TOTAL_UNDER}`].errorMsg = 'GAMELINECHANGE';
                betById[`${newState.id}_${PLAY_TYPE_TOTAL_UNDER}`].currentBase = newState.ou;
                betById[`${newState.id}_${PLAY_TYPE_TOTAL_UNDER}`].currentPrice = newState.um;
            }
        } else if (newState.ou === prevState.ou) {

            if (newState.om < prevState.om) {
                newState.omStyle = 'down';
                if (betById[`${newState.id}_${PLAY_TYPE_TOTAL_OVER}`]) {
                    betById[`${newState.id}_${PLAY_TYPE_TOTAL_OVER}`].errorMsg = 'GAMELINECHANGE';
                    betById[`${newState.id}_${PLAY_TYPE_TOTAL_OVER}`].currentBase = newState.ou;
                    betById[`${newState.id}_${PLAY_TYPE_TOTAL_OVER}`].currentPrice = newState.om;
                }
            } else if (newState.om > prevState.om) {
                newState.omStyle = 'up';
                if (betById[`${newState.id}_${PLAY_TYPE_TOTAL_OVER}`]) {
                    betById[`${newState.id}_${PLAY_TYPE_TOTAL_OVER}`].errorMsg = 'GAMELINECHANGE';
                    betById[`${newState.id}_${PLAY_TYPE_TOTAL_OVER}`].currentBase = newState.ou;
                    betById[`${newState.id}_${PLAY_TYPE_TOTAL_OVER}`].currentPrice = newState.om;
                }
            } else {
                newState.omStyle = '';
            }

            if (newState.um < prevState.um) {
                newState.umStyle = 'down';
                if (betById[`${newState.id}_${PLAY_TYPE_TOTAL_UNDER}`]) {
                    betById[`${newState.id}_${PLAY_TYPE_TOTAL_UNDER}`].errorMsg = 'GAMELINECHANGE';
                    betById[`${newState.id}_${PLAY_TYPE_TOTAL_UNDER}`].currentBase = newState.ou;
                    betById[`${newState.id}_${PLAY_TYPE_TOTAL_UNDER}`].currentPrice = newState.um;
                }
            } else if (newState.um > prevState.um) {
                newState.umStyle = 'up';
                if (betById[`${newState.id}_${PLAY_TYPE_TOTAL_UNDER}`]) {
                    betById[`${newState.id}_${PLAY_TYPE_TOTAL_UNDER}`].errorMsg = 'GAMELINECHANGE';
                    betById[`${newState.id}_${PLAY_TYPE_TOTAL_UNDER}`].currentBase = newState.ou;
                    betById[`${newState.id}_${PLAY_TYPE_TOTAL_UNDER}`].currentPrice = newState.um;
                }
            } else {
                newState.umStyle = '';
            }
        }
        //Handicap Market
        if (newState.apm === 0) {
            newState.asStyle = 'lock';
            newState.hsStyle = 'lock';
            if (betById[`${newState.id}_${PLAY_TYPE_VISITOR_SPREAD}`]) {
                betById[`${newState.id}_${PLAY_TYPE_VISITOR_SPREAD}`].errorMsg = 'GAMELINECHANGE';
                betById[`${newState.id}_${PLAY_TYPE_VISITOR_SPREAD}`].currentBase = 0;
                betById[`${newState.id}_${PLAY_TYPE_VISITOR_SPREAD}`].currentPrice = 0;
            }

            if (betById[`${newState.id}_${PLAY_TYPE_HOME_SPREAD}`]) {
                betById[`${newState.id}_${PLAY_TYPE_HOME_SPREAD}`].errorMsg = 'GAMELINECHANGE';
                betById[`${newState.id}_${PLAY_TYPE_HOME_SPREAD}`].currentBase = 0;
                betById[`${newState.id}_${PLAY_TYPE_HOME_SPREAD}`].currentPrice = 0;
            }
        } else if (newState.ap < prevState.ap) {
            newState.asStyle = 'down';
            newState.hsStyle = 'up';
            if (betById[`${newState.id}_${PLAY_TYPE_VISITOR_SPREAD}`]) {
                betById[`${newState.id}_${PLAY_TYPE_VISITOR_SPREAD}`].errorMsg = 'GAMELINECHANGE';
                betById[`${newState.id}_${PLAY_TYPE_VISITOR_SPREAD}`].currentBase = newState.ap;
                betById[`${newState.id}_${PLAY_TYPE_VISITOR_SPREAD}`].currentPrice = newState.apm;
            }

            if (betById[`${newState.id}_${PLAY_TYPE_HOME_SPREAD}`]) {
                betById[`${newState.id}_${PLAY_TYPE_HOME_SPREAD}`].errorMsg = 'GAMELINECHANGE';
                betById[`${newState.id}_${PLAY_TYPE_HOME_SPREAD}`].currentBase = newState.hp;
                betById[`${newState.id}_${PLAY_TYPE_HOME_SPREAD}`].currentPrice = newState.hpm;
            }
        } else if (newState.hp < prevState.hp) {
            newState.hsStyle = 'up';
            newState.asStyle = 'down';
            if (betById[`${newState.id}_${PLAY_TYPE_VISITOR_SPREAD}`]) {
                betById[`${newState.id}_${PLAY_TYPE_VISITOR_SPREAD}`].errorMsg = 'GAMELINECHANGE';
                betById[`${newState.id}_${PLAY_TYPE_VISITOR_SPREAD}`].currentBase = newState.ap;
                betById[`${newState.id}_${PLAY_TYPE_VISITOR_SPREAD}`].currentPrice = newState.apm;
            }

            if (betById[`${newState.id}_${PLAY_TYPE_HOME_SPREAD}`]) {
                betById[`${newState.id}_${PLAY_TYPE_HOME_SPREAD}`].errorMsg = 'GAMELINECHANGE';
                betById[`${newState.id}_${PLAY_TYPE_HOME_SPREAD}`].currentBase = newState.hp;
                betById[`${newState.id}_${PLAY_TYPE_HOME_SPREAD}`].currentPrice = newState.hpm;
            }
        } else {
            if (newState.apm < prevState.apm) {
                newState.asStyle = 'down';
                if (betById[`${newState.id}_${PLAY_TYPE_VISITOR_SPREAD}`]) {
                    betById[`${newState.id}_${PLAY_TYPE_VISITOR_SPREAD}`].errorMsg = 'GAMELINECHANGE';
                    betById[`${newState.id}_${PLAY_TYPE_VISITOR_SPREAD}`].currentBase = newState.ap;
                    betById[`${newState.id}_${PLAY_TYPE_VISITOR_SPREAD}`].currentPrice = newState.apm;
                }
            } else if (newState.apm > prevState.apm) {
                newState.asStyle = 'up';
                if (betById[`${newState.id}_${PLAY_TYPE_VISITOR_SPREAD}`]) {
                    betById[`${newState.id}_${PLAY_TYPE_VISITOR_SPREAD}`].errorMsg = 'GAMELINECHANGE';
                    betById[`${newState.id}_${PLAY_TYPE_VISITOR_SPREAD}`].currentBase = newState.ap;
                    betById[`${newState.id}_${PLAY_TYPE_VISITOR_SPREAD}`].currentPrice = newState.apm;
                }
            } else {
                newState.asStyle = '';
            }

            if (newState.hpm < prevState.hpm) {
                newState.hsStyle = 'down';
                if (betById[`${newState.id}_${PLAY_TYPE_HOME_SPREAD}`]) {
                    betById[`${newState.id}_${PLAY_TYPE_HOME_SPREAD}`].errorMsg = 'GAMELINECHANGE';
                    betById[`${newState.id}_${PLAY_TYPE_HOME_SPREAD}`].currentBase = newState.hp;
                    betById[`${newState.id}_${PLAY_TYPE_HOME_SPREAD}`].currentPrice = newState.hpm;
                }
            } else if (newState.hpm > prevState.hpm) {
                newState.hsStyle = 'up';
                if (betById[`${newState.id}_${PLAY_TYPE_HOME_SPREAD}`]) {
                    betById[`${newState.id}_${PLAY_TYPE_HOME_SPREAD}`].errorMsg = 'GAMELINECHANGE';
                    betById[`${newState.id}_${PLAY_TYPE_HOME_SPREAD}`].currentBase = newState.hp;
                    betById[`${newState.id}_${PLAY_TYPE_HOME_SPREAD}`].currentPrice = newState.hpm;
                }
            } else {
                newState.hsStyle = '';
            }
        }
        //2Way/3Way Market
        if (newState.am < prevState.am) {
            newState.amStyle = 'down';
        } else if (newState.am > prevState.am) {
            newState.amStyle = 'up';
        } else {
            newState.amStyle = '';
        }

        if (newState.hm < prevState.hm) {
            newState.hmStyle = 'down';
        } else if (newState.hm > prevState.hm) {
            newState.hmStyle = 'up';
        } else {
            newState.hmStyle = '';
        }

        if (newState.dm < prevState.dm) {
            newState.dmStyle = 'down';
        } else if (newState.dm > prevState.dm) {
            newState.dmStyle = 'up';
        } else {
            newState.dmStyle = '';
        }
    }
    
};

const BetslipReducer = (state = initialState, action) => {
    const ret = { ...state };
    switch (action.type) {
        case BetslipActionType.SET_TYPE:
            ret.betType = action.payload.betType;
            ret.betStyle = action.payload.betStyle;
            ret.amountType = 0;
            ret.errorMsg = '';
            ret.ticketId = '';
            ret.singleStake = 0;
            ret.teaserOpts = [];
            ret.teaserId = 0;
            ret.openSlots = 0;
            ret.slotId = 0;
            ret.wager = undefined;
            return ret;
        case BetslipActionType.SET_OPEN_STATE:
            ret.betslipOpen = !state.betslipOpen;
            return ret;
        case BetslipActionType.TOGGLE_FREEPLAY:
            ret.useFreePlay = !state.useFreePlay;
            return ret;
        case BetslipActionType.READBACK_OPEN_STATE:
            ret.readbackOpen = !state.readbackOpen;
            if (!ret.readbackOpen)
                ret.readback = null;
            return ret;
        case BetslipActionType.SET_MENU_STATE:
            ret.sportMenuOpen = !state.sportMenuOpen;
            return ret;
        case BetslipActionType.BOOK_STAKE:
            ret.minWager = action.payload.minWager;
            ret.maxWager = action.payload.maxWager;
            return ret;
        case BetslipActionType.STAKE_TYPE:
            ret.amount = action.payload.amount;
            return ret;
        case BetslipActionType.SEAT_TYPE:
            ret.openSlots = action.payload.seat;
            return ret;
        case BetslipActionType.PICK_MSG:
            ret.byId[action.payload.eventId] = action.payload;
            return ret;
        case BetslipActionType.TEASER_STYLE:
            ret.teaserId = action.payload;
            return ret;
        case BetslipActionType.BET_TEASERRULES:
            ret.status = BetStatusEnum.NONE;
            ret.teaserOpts = action.payload;
            ret.teaserId = action.payload[0].idWagerType;
            return ret;
        case BetslipActionType.ADD:
            const ids = state.byId[action.payload.eventId] ? state.ids : [...state.ids, action.payload.eventId];
            ret.ids = ids;
            ret.byId[action.payload.eventId] = action.payload;
            ret.teaserOpts = [];
            ret.teaserId = 0;
            return ret;
        case BetslipActionType.REMOVE:
            if (ret.byId[action.payload.eventId]) {
                delete ret.byId[action.payload.eventId];
                ret.ids = ret.ids.filter(id => id !== action.payload.eventId);
            }
            ret.errorMsg = '';
            ret.status = BetStatusEnum.NONE;
            ret.ticketId = '';
            ret.singleStake = 0;
            ret.teaserOpts = [];
            ret.teaserId = 0;
            return ret;
        case BetslipActionType.MOVE_UP:
            ret.ids = [action.payload.eventId].concat(ret.ids.filter(id => id !== action.payload.eventId));
            return ret;
        case BetslipActionType.PICK_OPTS:
            const tokens = action.payload.tag.split('_');
            const play = +tokens[1];
            const bidBase = +tokens[2];
            const bidPrice = +tokens[3];
            const buyingPoints = +tokens[4];
            ret.byId[action.payload.eventId] = {
                ...ret.byId[action.payload.eventId],
                limit: action.payload.limit,
                offers: action.payload.offers,
                initialPrice: bidPrice,
                initialBase: play === 2 ? Math.abs(bidBase) : bidBase,
                handicap: buyingPoints
            };
            
            if (action.payload.singleStake) {
                if (ret.amount === 0)
                    ret.singleStake = (action.payload.singleStake / 1000);
                else
                    ret.singleStake = action.payload.singleStake;

                if (action.payload.opts && action.payload.opts.length) {
                    ret.teaserOpts = action.payload.opts.map(val => {
                        const chunks = val.split('_');
                        let fieldIndex = 0;
                        return {
                            id: +chunks[fieldIndex++],
                            label: chunks[fieldIndex++],
                            price1: +chunks[fieldIndex++],
                            price2: +chunks[fieldIndex++],
                            price3: +chunks[fieldIndex++]
                        }
                    });
                    ret.teaserId = ret.teaserId === 0 ? ret.teaserOpts[0].id : ret.teaserId;
                }
            }
            else if (ret.amount > 0)
            {
                ret.ids.map(id => {
                    const bet = ret.byId[id];
                    const { win, risk } = getRiskAndWin(ret.amountType, ret.amount, bet.bidPrice);
                    bet.singleWin = win;
                    bet.singleRisk = risk;
                });
            }
            return ret;
        case BetslipActionType.CLEAR:
            ret.ids.map(id => { delete ret.byId[id] });
            ret.ids = [];
            ret.byId = {};
            ret.status = BetStatusEnum.NONE;
            ret.errorMsg = '';
            ret.ticketId = '';
            ret.betslipOpen = false;
            ret.singleStake = 0;
            ret.teaserOpts = [];
            ret.teaserId = 0;
            ret.useFreePlay = false;
            return ret;
        case BetslipActionType.SET_ITEM_STAKE:
            ret.byId[action.payload.eventId] = action.payload;
            return ret;
        case BetslipActionType.RESET_STATUS:
            ret.status = BetStatusEnum.NONE;
            ret.ids.map(id => {
                if (ret.byId[id]) {
                    ret.byId[id].processing = false;
                }
            });
            return ret;
        case BetslipActionType.PROCESSING:
            ret.status = BetStatusEnum.PROCESS;
            ret.errorMsg = '';
            ret.ticketId = '';
            ret.ids.map(id => {
                if (ret.byId[id]) {
                    ret.byId[id].processing = true;
                    ret.byId[id].errorMsg = '';
                }
            });
            return ret;
        case BetslipActionType.BET_FAILED:
            ret.status = BetStatusEnum.FAIL;
            ret.errorMsg = action.payload.msg;
            ret.ids.map(id => {
                if (ret.byId[id]) {
                    ret.byId[id].processing = false;
                    const bet = action.payload.bets.find(i => i.id.indexOf(id) === 0);
                    if (bet) {
                        ret.byId[id].errorMsg = bet.msg;
                        if (bet.msg.indexOf('GAMELINECHANGE') > -1) {
                            ret.byId[id].errorMsg = 'GAMELINECHANGE';
                            const tokens = bet.msg.split('_');
                            ret.byId[id].currentBase = +tokens[1];
                            ret.byId[id].currentPrice = +tokens[2];
                        }
                    }
                }
            });
            return ret;
        case BetslipActionType.BET_ACCEPTED:
            ret.ids.map(id => { delete ret.byId[id] });
            ret.ids = [];
            ret.byId = {};
            ret.status = BetStatusEnum.NONE;
            ret.errorMsg = '';
            ret.ticketId = '';
            ret.singleStake = 0;
            ret.teaserOpts = [];
            ret.teaserId = 0;
            ret.betType = 0;
            ret.betStyle = 0;
            ret.openSlots = 0;
            ret.slotId = 0;
            ret.wager = undefined;
            ret.useFreePlay = false;
            ret.readback = action.payload.readback;
            ret.betslipOpen = false;
            ret.readbackOpen = true;
            return ret;
        case BetslipActionType.SET_OPENBET:
            if (ret.wager || action.payload) {
                ret.slotId = 0;
                ret.wager = undefined;
                ret.ids.map(id => { delete ret.byId[id] });
                ret.ids = [];
                ret.byId = {};
                ret.status = BetStatusEnum.NONE;
                ret.errorMsg = '';
                ret.ticketId = '';
                ret.singleStake = 0;
                ret.teaserOpts = [];
                ret.teaserId = 0;
                ret.betType = 0;
                ret.betStyle = 0;
                ret.openSlots = 0;
                ret.amount = 0;

                if (action.payload) {
                    ret.slotId = action.payload.idWager;
                    ret.wager = action.payload;
                    ret.openSlots = action.payload.openItems;
                    ret.betType = 1;
                    ret.betStyle = 0;
                    ret.amount = action.payload.risk;
                }
            }
            return ret;
        case BetslipActionType.SELECTED_LEAGUES:
            ret.status = BetStatusEnum.PROCESS;
            ret.leagueIds = action.payload;
            return ret;
        case BetslipActionType.GAMES_LOADED:
            const betById = ret.byId;
            const prevKeys = ret.gameById || {}; 
            const newKeys = action.payload.byId || {}; 
            const newTree = action.payload.tree || []; 

            Object.keys(newKeys).forEach(eventId => {
                const newBet = newKeys[eventId];
                const prevBet = prevKeys[eventId];

                compareAndSetStyles(newBet, prevBet, betById);
            });
            ret.status = BetStatusEnum.NONE;
            ret.games = newTree;
            ret.gameById = newKeys;
            return ret;
        default:
            return state;
    }
};

export default BetslipReducer;