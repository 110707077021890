import React, { Component } from 'react';
import { connect } from 'react-redux';
import withRouter from "../hooks/withRouter";
import { STORAGE_KEY, Base64 } from '../store/auth/types';

const parseJwt = (token) => {
    try {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    } catch (e) {
        return null;
    }
};

class CustomWindow extends Component {
    static displayName = CustomWindow.name;

    constructor(props) {
        super(props);
        this.state = {
            contentHeight: '100px',
            url: '',
            loading: true
        };
        this.didMount = false;
    }

    handleResize = (e) => {
        if (this.didMount) {
            this.setState({ contentHeight: (window.innerHeight - 48) + 'px' });
        }
    };

    handleRedirectRequest() {
        const token = localStorage.getItem(STORAGE_KEY);
        const payload = parseJwt(token);
        const subOpts = payload.sub;
        const tokens = subOpts.split('~');
        const search = this.props.location.search;
        const target = new URLSearchParams(search).get("t");
        const url = Base64.decode(target)
            .replace("[IDPLAYER]", payload.id)
            .replace("[USERNAME]", this.props.userName)
            .replace("[PASSWORD]", tokens[12].split("").reverse().join(""));
        this.setState({ url, loading: false });
    }

    componentDidMount() {
        this.didMount = true;
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
        this.handleRedirectRequest();
    }

    componentWillUnmount = () => {
        this.didMount = false;
        window.removeEventListener('resize', this.handleResize);
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location !== prevProps.location) {
            this.handleRedirectRequest();
        }
    }

    render() {
        if (this.state.loading)
            return <p>Loading...</p>

        return (
            <iframe
                src={this.state.url}
                height={this.state.contentHeight}
                width="100%"
                allowFullScreen="1"
                frameBorder="0"
                scrolling="auto">
            </iframe>
        );
    }
}

function mapStateToProps(state) {
    return {
        token: state.auth.token,
        userName: state.auth.userName,
        balance: state.balance
    };
}

export default connect(mapStateToProps)(withRouter(CustomWindow));