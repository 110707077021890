import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { actionCreators as BetslipActions } from '../store/betslip/actions';
import { getBetslipData, getRiskAndWin } from '../helpers/betslip';
import BetslipItem from './BetslipItem';
import { BetStatusEnum } from '../store/betslip/types';
import { RawHtml } from './RawHtml';
import { injectIntl } from 'react-intl';

class Betslip2 extends Component {
    constructor(props) {
        super(props);
        this.amountInput = React.createRef();
        this.seatInput = React.createRef();
        this.state = {
            amount: props.amount,
            seat: props.openSlots,
            amountType: props.amountType,
            betType: props.betType
        }
        this.onBetTypeChange = this.onBetTypeChange.bind(this);
        this.onAmountTypeChange = this.onAmountTypeChange.bind(this);
        this.onAmountChange = this.onAmountChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onTeaserStyleChange = this.onTeaserStyleChange.bind(this);
        this.clearShoppingCart = this.clearShoppingCart.bind(this);
        this.onSeatChange = this.onSeatChange.bind(this);
    }


    clearShoppingCart() {
        const amount = this.props.amount;
        this.setState({ amount });
        this.props.clearShoppingCart();
    }

    onBetTypeChange = (betType) => {
        this.props.setBetType(betType);
        const seat = 0;
        this.setState({ seat });
        if (betType === 0) {
            const { amount, amountType } = this.state;
            this.updateBetslipItemStakes(amount, amountType, betType);
        }
    }

    onTeaserStyleChange = (e) => {
        const teaserId = +e.target.value;
        this.props.setTeaserStyle(teaserId);
    }

    onAmountTypeChange = (e) => {
        const amountType = +e.target.value;
        const { amount } = this.state;
        this.setState({ amountType });
        this.updateBetslipItemStakes(amount, amountType, this.props.betType);
    }

    onAmountChange = (e) => {
        const amount = +e.target.value;
        const { amountType } = this.state;
        this.setState({ amount });
        this.updateBetslipItemStakes(amount, amountType, this.props.betType);
    }

    onSeatChange = (e) => {
        let seat = +e.target.value;
        if (seat > 7)
            seat = 7;
        this.setState({ seat });
        this.props.setOpenSeatOpts({ seat });
    }

    updateBetslipItemStakes(amount, amountType, betType) {
        if (betType === 0) {
            for (var i = 0; i < this.props.cartItemList.length; i++) {
                const bet = this.props.cartItemList[i];
                const { win, risk } = getRiskAndWin(amountType, amount, bet.bidPrice);
                this.props.itemStakeChange(bet, { win, risk });
            }
        }
        this.props.setBettingOpts({ amount });
    }

    handleSubmit = () => {
        const { status, info } = this.props;
        const isDisabled = (info.hasWrongStakes || info.hasEmptyStakes) || status === BetStatusEnum.PROCESS;
        if (!isDisabled) {
            this.props.sendBets();
        }
    }

    componentDidMount() {
        if (this.amountInput && this.amountInput.current)
            this.amountInput.current.focus();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.amount !== prevProps.amount) {
            this.setState({ amount: this.props.amount });
        }
    }

    render() {
        const { possibleWin, totalRisk, status, info, minWager, maxWager, teaserOpts, parlayOpts, ifBetOpts } = this.props;
        const { amount, amountType, seat } = this.state;
        const isDisabled = (info.hasWrongStakes || info.hasEmptyStakes) || status === BetStatusEnum.PROCESS;
        const isFinal = status === BetStatusEnum.SUCCESS;
        const straight1 = this.props.intl.formatMessage({ id: 'straight' });
        const teaser = this.props.intl.formatMessage({ id: 'teaser' });
        const parley = this.props.intl.formatMessage({ id: 'parley' });
        const ifBets = this.props.intl.formatMessage({ id: 'ifBets' });
        const wager = this.props.intl.formatMessage({ id: 'wager' });
        const risk = this.props.intl.formatMessage({ id: 'risk' });
        const win = this.props.intl.formatMessage({ id: 'win' });
        const totalRisk1 = this.props.intl.formatMessage({ id: 'totalRisk' });
        const potentialWin = this.props.intl.formatMessage({ id: 'potentialWin' });
        const placeBet = this.props.intl.formatMessage({ id: 'placeBet' });
        const loading = this.props.intl.formatMessage({ id: 'loading...' });
        const deleteAll = this.props.intl.formatMessage({ id: "deleteAll" });
        const useFreePlay = this.props.intl.formatMessage({ id: "useFreePlay" });
        const openSpots = this.props.intl.formatMessage({ id: "openSpots" });
        const startBetting = this.props.intl.formatMessage({ id: "startBetting" });
        const cont = this.props.intl.formatMessage({ id: "continue" });
        const addSelections = this.props.intl.formatMessage({ id: "addSelections" });

            return (
                <div className={'app-betslip ' + (this.props.isOpen ? 'isShown' : '')}>
                    <div className="bs-content custom-scrollbar">
                        <div className="bs-header">
                            <div className="offcanvas-header  d-lg-none">
                                <h5 className="offcanvas-title">Betslip</h5>
                                <button type="button"
                                    className="btn-close"
                                    data-bs-dismiss="offcanvas"
                                    aria-label="Close"
                                    onClick={this.props.toggleBetslip} />
                            </div>
                            <Nav pills>
                                <NavItem>
                                    <NavLink
                                        onClick={() => { this.onBetTypeChange(0) }}
                                        active={this.props.betType === 0}>
                                        {straight1}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        onClick={() => { this.onBetTypeChange(2) }}
                                        active={this.props.betType === 2}>
                                        {teaser}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        onClick={() => { this.onBetTypeChange(1) }}
                                        active={this.props.betType === 1}>
                                        {parley}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        onClick={() => { this.onBetTypeChange(3) }}
                                        active={this.props.betType === 3}>
                                        {ifBets}
                                    </NavLink>
                                </NavItem>
                            </Nav>

                            {(teaserOpts && teaserOpts.length > 1) &&
                                <div className="p-2">
                                    <select className="form-select" aria-label="Teaser Type" name='teaserType' value={this.props.teaserId} onChange={this.onTeaserStyleChange}>
                                        {teaserOpts.map((teaser, index) => {
                                            return <option value={teaser.id} key={index}>{teaser.label}</option>
                                        })}
                                    </select>
                                </div>
                            }

                            {(parlayOpts && parlayOpts.length > 1 && this.props.slotId === 0) &&
                                <div className="p-2">
                                    <select className="form-select" aria-label="Parlay Type" name='parlayType' value={this.props.teaserId} onChange={this.onTeaserStyleChange}>
                                        {parlayOpts.map((parlay, index) => {
                                            return <option value={parlay.id} key={index}>{parlay.label}</option>
                                        })}
                                    </select>
                                </div>
                            }

                            {(this.props.slotId > 0) &&
                                <div className="p-2">
                                    <button className="btn btn-primary w-100" onClick={(event) => this.props.toggleOpenWager(undefined)}>{this.props.intl.formatMessage({ id: 'cancelFillOpen' })}</button>
                                </div>
                            }
                            {(this.props.slotId > 0) &&
                                <div className="p-2">
                                    <h6 className="card-title">{this.props.wager.desc}</h6>
                                    <div className="row align-items-center">
                                        <div className="col-auto">
                                            <small className="text-muted">
                                                {this.props.wager.openItems}&nbsp;{this.props.intl.formatMessage({ id: 'openSpots' })}
                                            </small>
                                        </div>
                                        <div className="col-auto">
                                            {this.props.intl.formatMessage({ id: 'risk' })} {this.props.auth.currencySymbol}{this.props.wager.risk.toLocaleString()}
                                        </div>
                                        <div className="col-auto">
                                            {this.props.intl.formatMessage({ id: 'toWin' })} {this.props.auth.currencySymbol}{this.props.wager.toWin.toLocaleString()}
                                        </div>
                                    </div>
                                </div>
                            }
                            {(ifBetOpts && ifBetOpts.length > 1) &&
                                <div className="p-2">
                                    <select className="form-select" aria-label="IfBet Type" name='ifBetType' value={this.props.teaserId} onChange={this.onTeaserStyleChange}>
                                        {ifBetOpts.map((ifBet, index) => {
                                            return <option value={ifBet.id} key={index}>{ifBet.label}</option>
                                        })}
                                    </select>
                                </div>
                            }
                        </div>
                        <div className="bs-menu custom-scrollbar">
                            <ul className="list-group list-group-flush px-1">
                                {this.props.slotId > 0 && this.props.wager && this.props.wager.items.filter(b => b.idGame).map((item, index) => (
                                    <li key={index} className="list-group-item py-1 px-0">
                                        <div className="row g-0 custom-scrollbar">
                                            <div className="col-auto me-2">
                                                #{(index + 1)}
                                            </div>
                                            <div className="col">
                                                <div className="row g-0">
                                                    <div className="col-12">
                                                        {item.idSport} <RawHtml>{item.desc}</RawHtml>
                                                    </div>
                                                    <div className="col-12">
                                                        <small className="text-muted">
                                                            {item.gameDate}
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))
                                }
                                {this.props.cartItemList.map((item, index) => {
                                    return <BetslipItem
                                        key={item.eventId}
                                        item={item}
                                        itemIndex={index}
                                        betType={this.props.betType}
                                        isFinal={isFinal}
                                        minWager={minWager}
                                        maxWager={maxWager}
                                        currency={this.props.balance.currency}
                                        removeItem={this.props.removeItem}
                                        moveItemUpward={this.props.moveItemUpward}
                                        clearShoppingCart={this.clearShoppingCart}
                                        itemStakeChange={this.props.itemStakeChange}
                                        handleSubmit={this.handleSubmit} />
                                })}
                            </ul>

                        </div>
                        <div className="bs-footer">
                            <ul className="summary pb-1">
                                {status === BetStatusEnum.FAIL &&
                                    <li>
                                        <div className="alert alert-danger" role="alert">
                                            <RawHtml>{this.props.errorMsg}</RawHtml>
                                        </div>
                                    </li>
                                }
                                <li>
                                    <div className="group twoItems">
                                        <div className="cell">
                                            <button className="btn btn-danger btn-sm w-100" type="button" onClick={this.clearShoppingCart}>{deleteAll}</button>
                                        </div>
                                        <div className="cell">
                                            {(this.props.balance.freePlay > 0) && (
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" id="useFreePlay" checked={this.props.useFreePlay}
                                                        onChange={this.props.toggleFreeplay} />
                                                    <label className="form-check-label" htmlFor="useFreePlay"><small>{useFreePlay} ({this.props.balance.freePlay.toLocaleString()})</small></label>
                                                </div>)
                                            }
                                        </div>
                                    </div>
                                </li>
                                {(this.props.slotId === 0) &&
                                    <li>
                                        <div className="group twoItems">
                                            <div className="cell">
                                                <select className="form-select" aria-label="Wager Amount" name='amountType' value={amountType} onChange={this.onAmountTypeChange}>
                                                    <option value="0">{wager}</option>
                                                    <option value="1">{risk}</option>
                                                    <option value="2">{win}</option>
                                                </select>
                                            </div>
                                            <div className="cell">
                                                <input name='amount' type="number" aria-label="Amount" className="form-control form-control-sm" placeholder="Amount" value={amount.toString()}
                                                    ref={this.amountInput}
                                                    onKeyPress={event => event.key === "Enter" && this.handleSubmit(event)}
                                                    onChange={this.onAmountChange} autoComplete="off" min="0" step="1" pattern="^[0-9]+" />
                                            </div>
                                        </div>
                                    </li>
                                }
                                {(this.props.betType === 1 && this.props.slotId === 0) &&
                                    <li>
                                        <div className="group twoItems">
                                            <div className="cell">
                                                <span>{openSpots}</span>
                                            </div>
                                            <div className="cell">
                                                <input name='openSpots' type="number" aria-label="Open Spots" className="form-control form-control-sm" placeholder="Open Spots" value={seat.toString()}
                                                    ref={this.seatInput}
                                                    onChange={this.onSeatChange} autoComplete="off" min="0" max="5" step="1" pattern="^[0-9]+" />
                                            </div>
                                        </div>
                                    </li>
                                }
                                <li>
                                    <div className="group twoItems">
                                        <div className="cell"><span>{totalRisk1}</span></div>
                                        <div className="cell aside">
                                            <span>{totalRisk.toString()}&nbsp;{this.props.balance.currency}</span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="group twoItems">
                                        <div className="cell">
                                            <span>{potentialWin}</span>
                                        </div>
                                        <div className="cell aside">
                                            <span>{possibleWin.toString()}&nbsp;{this.props.balance.currency}</span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    {(status !== BetStatusEnum.SUCCESS && status !== BetStatusEnum.PROCESS) &&
                                        <button className="btn btn-success w-100" type="button" disabled={isDisabled} onClick={this.props.sendBets}>
                                            {placeBet}&nbsp;({totalRisk.toString()}&nbsp;{this.props.balance.currency})
                                        </button>
                                    }
                                    {status === BetStatusEnum.PROCESS &&
                                        <div className="spinner-border" role="status">
                                            <span className="visually-hidden">{loading}</span>
                                        </div>
                                    }
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            );
    }
}


const mapStateToProps = (state) => {
    const { events, info, totalRisk, possibleWin, parlayOpts, ifBetOpts } = getBetslipData(state.betslip);
    return {
        cartItemList: events,
        cartItemCount: events.length,
        amount: state.betslip.amount,
        amountType: state.betslip.amountType,
        betType: state.betslip.betType,
        totalRisk,
        possibleWin,
        info,
        balance: state.balance,
        status: state.betslip.status,
        errorMsg: state.betslip.errorMsg,
        minWager: state.betslip.minWager,
        maxWager: state.betslip.maxWager,
        ticketId: state.betslip.ticketId,
        teaserOpts: state.betslip.teaserOpts,
        teaserId: state.betslip.teaserId,
        slotId: state.betslip.slotId,
        wager: state.betslip.wager,
        useFreePlay: state.betslip.useFreePlay,
        openSlots: state.betslip.openSlots,
        parlayOpts,
        ifBetOpts,
        auth: state.auth,
    }
}

const mapDispatchToProps = {
    removeItem: BetslipActions.removeItem,
    moveItemUpward: BetslipActions.moveItemUpward,
    itemStakeChange: BetslipActions.setItemStake,
    setBettingOpts: BetslipActions.setBettingOpts,
    setOpenSeatOpts: BetslipActions.setOpenSeatOpts,
    clearShoppingCart: BetslipActions.clear,
    setBetType: BetslipActions.setBetType,
    setTeaserStyle: BetslipActions.setTeaserStyle,
    toggleFreeplay: BetslipActions.toggleFreeplay,
    toggleOpenWager: BetslipActions.toggleOpenWager
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Betslip2));