import OddConverter, { formatHandicap, formatTotal } from '../helpers/oddConverter';

export const BETSLIP_TYPE_STRAIGHT = 0;
export const BETSLIP_TYPE_PARLAY = 1;
export const BETSLIP_TYPE_TEASER = 2;
export const BETSLIP_TYPE_IFWIN = 3;
export const BETSLIP_TYPE_REVERSEWIN = 4;
export const BETSLIP_TYPE_ROUNDROBIN = 5;
export const BETSLIP_TYPE_IFACTION = 7;
export const BETSLIP_TYPE_REVERSEACTION = 8;
export const BETSLIP_TYPE_IFLOSE = 10;
export const BETSLIP_TYPE_IFLOSEACTION = 11;
export const BETSLIP_TYPE_BETITALL = 35;
export const BETSLIP_TYPE_COMPACTROUNDROBIN = 15;
export const BETSLIP_TYPE_KEYPARLAY = 16;
export const BETSLIP_TYPE_COMPACTIFWIN = 17;
export const BETSLIP_TYPE_COMPACTIFWINORTIE = 18;
export const BETSLIP_TYPE_COMPACTIFLOSE = 19;
export const BETSLIP_TYPE_COMPACTIFLOSEORTIE = 20;
export const BETSLIP_TYPE_COMPACTREVERSEWIN = 21;
export const BETSLIP_TYPE_COMPACTREVERSEWINORTIE = 22;

export const PLAY_TYPE_VISITOR_SPREAD = 0;
export const PLAY_TYPE_HOME_SPREAD = 1;
export const PLAY_TYPE_TOTAL_OVER = 2;
export const PLAY_TYPE_TOTAL_UNDER = 3;
export const PLAY_TYPE_VISITOR_ODDS = 4;
export const PLAY_TYPE_HOME_ODDS = 5;
export const PLAY_TYPE_DRAW_ODDS = 6;

export const generateSelectionId = (fixture, play) => (play === PLAY_TYPE_VISITOR_ODDS && fixture.hm === 0 && fixture.am !== 0) ? `${fixture.id}_${fixture.rot}` : `${fixture.id}_${play}`;
export const getSelectionOffer = (item, format, betType) => {
    if (betType === BETSLIP_TYPE_TEASER) {
        switch (item.play) {
            case 0:
            case 1:
                return formatHandicap(item.currentBase + item.handicap).concat(OddConverter(item.initialPrice, format)) + ' (B+' + (item.handicap) + ')';
            case 2:
                return formatTotal(item.currentBase - item.handicap, 'o').concat(OddConverter(item.initialPrice, format)) + ' (B+' + (item.handicap) + ')';
            case 3:
                return formatTotal(item.currentBase + item.handicap, 'u').concat(OddConverter(item.initialPrice, format)) + ' (B+' + (item.handicap) + ')';
        }
    }

    switch (item.play) {
        case 0:
        case 1:
            return formatHandicap(item.initialBase).concat(OddConverter(item.initialPrice, format));
        case 2:
            return formatTotal(item.initialBase, 'o').concat(OddConverter(item.initialPrice, format));
        case 3:
            return formatTotal(item.initialBase, 'u').concat(OddConverter(item.initialPrice, format));
        default:
            return OddConverter(item.initialPrice, format)
    }
};

export const getSelectionBidOffer = (item, bid, format) => {
    const tokens = bid.split('_');
    const base = +tokens[1];
    const price = +tokens[2];
    switch (item.play) {
        case 0:
        case 1:
            return formatHandicap(base).concat(OddConverter(price, format));
        case 2:
            return formatTotal(Math.abs(base), 'o').concat(OddConverter(price, format));
        case 3:
            return formatTotal(base, 'u').concat(OddConverter(price, format));
        default:
            return OddConverter(base, format)
    }
};

export const calculateRiskOrWin = (price, betSize) => price > 0 ? Math.round((betSize * price / 100), 2) : Math.round(Math.abs(betSize * 100 / price), 2);
export function getRiskAndWin(amountType, amount, price) {
    let win = 0;
    let risk = 0;
    switch (amountType) {
        case 0:
            if (price > 0) {
                risk = amount;
                win = calculateRiskOrWin(price, risk);
            } else if (price < 0) {
                win = amount;
                risk = calculateRiskOrWin(-price, win);
            }
            break;
        case 1:
            risk = amount;
            win = calculateRiskOrWin(price, risk);
            break;
        case 2:
            win = amount;
            risk = calculateRiskOrWin(-price, win);
            break;

        default:
            break;
    }
    return { win, risk };
}

const getSelectionLabel = (fixture, play, translator) => {
    switch (play) {
        case 0:
            return fixture.vtm;
        case 1:
            return fixture.htm;
        case 2:
            return translator.formatMessage({ id: 'theOver' });
        case 3:
            return translator.formatMessage({ id: 'theUnder' });
        case 4:
            return fixture.vtm;
        case 5:
            return fixture.htm;
        case 6:
            return translator.formatMessage({ id: 'theDraw' });
        default:
            return fixture.vtm;
    }
}

export const getBetTypeLabel = (betType) => {
    switch (betType) {
        case BETSLIP_TYPE_STRAIGHT:
            return 'Straight';
        case BETSLIP_TYPE_PARLAY:
            return 'Parlay';
        case BETSLIP_TYPE_TEASER:
            return 'Teaser';
        default:
            return 'Unknown';
    }
}

const getSelectionPrice = (fixture, play) => {
    switch (play) {
        case 0:
            return fixture.apm;
        case 1:
            return fixture.hpm;
        case 2:
            return fixture.om;
        case 3:
            return fixture.um;
        case 4:
            return fixture.am;
        case 5:
            return fixture.hm;
        case 6:
            return fixture.dm;
        default:
            return fixture.am;
    }
}

const getSelectionBase = (fixture, play) => {
    switch (play) {
        case 0:
            return fixture.ap;
        case 1:
            return fixture.hp;
        case 2:
            return fixture.ou;
        case 3:
            return fixture.ou;
        default:
            return 0;
    }
}

const getSelectionRot = (fixture, play) => {
    switch (play) {
        case 0:
            return fixture.rot;
        case 1:
            return fixture.rot+1;
        case 2:
            return fixture.rot;
        case 3:
            return fixture.rot + 1;
        case 4:
            return fixture.rot;
        case 5:
            return fixture.rot + 1;
        case 6:
            return fixture.rot + 2;
        default:
            return fixture.rot;
    }
}

export const createBetslipEventObject = (game, play, competition, sport, translator) => {
    let title = '';

    if (game.htm)
        title = game.vtm + " vs " + (game.htm || "");

    const initialPrice = getSelectionPrice(game, play);
    const initialBase = getSelectionBase(game, play);

    return {
        gameId: game.id,
        eventId: generateSelectionId(game, play),
        competition: competition,
        title: title,
        notes: game.desc,
        selection: getSelectionLabel(game, play, translator),
        play: play,
        rot: getSelectionRot(game, play),
        vpt: game.vpt,
        hpt: game.hpt,
        initialPrice: initialPrice,
        initialBase: initialBase,
        currentPrice: initialPrice,
        currentBase: initialBase,
        bidPrice: initialPrice,
        bidBase: 0,
        singleRisk: 0,
        singleWin: 0,
        limit: 0,
        offers: [],
        bidIdx: 0,
        handicap: 0,
        processing: false,
        errorMsg: '',
        ticketId: '',
        sport
    };
};

const getFactorial = (x) => {
    let num = 1;
    for (let i = 1; i <= x; i++)
    {
        num *= i;
    }
    return num;
}

const getCombinations = (c, x) => {
    let num = c - x;
    let num2 = num + 1;
    for (let i = num + 2; i <= c; i++)
    {
        num2 *= i;
    }
    return num2 / getFactorial(x);
}

export function getBetslipData(storeBetSlipData) {
    let events = [], info = {}, sameGameIds = {}, totalRisk = 0, possibleWin = 0, parlayOpts = [];
    let ifBetOpts = [];
    if (storeBetSlipData) {
        const minWager = storeBetSlipData.minWager;
        const maxWager = storeBetSlipData.maxWager;
        const betType = storeBetSlipData.betType;
        const betSlipAmount = storeBetSlipData.amount;
        const singleStake = storeBetSlipData.singleStake;
        let itemIdx = 0;
        let count = storeBetSlipData.ids.length;
        let multiplier = 1;
        let singleItemsOK = 0;
        storeBetSlipData.ids.map(eventId => {
            var bet = storeBetSlipData.byId[eventId];
            info.hasEventsFromSameGame = sameGameIds[bet.gameId] ? true : info.hasEventsFromSameGame;
            sameGameIds[bet.gameId] = true;
            if (betType === 0) {
                const hasWrongRisk = (isNaN(parseFloat(bet.singleRisk)) && bet.singleRisk !== "") || parseFloat(bet.singleRisk) < 0;
                const hasWrongWin = (isNaN(parseFloat(bet.singleWin)) && bet.singleWin !== "") || parseFloat(bet.singleWin) < 0;
                const amount = Math.min(bet.singleRisk, bet.singleWin);
                bet.hasWrongStakes = (hasWrongRisk || hasWrongWin || amount > bet.limit || amount > maxWager || (amount > 0 && amount < minWager));
                bet.hasEmptyStakes = (bet.singleRisk === 0 || bet.singleWin === 0);
                totalRisk += bet.singleRisk;
                possibleWin += bet.singleWin;

                if (!bet.hasWrongStakes && !bet.hasEmptyStakes) {
                    singleItemsOK++;
                }
            } else {
                totalRisk = betSlipAmount;
                possibleWin = singleStake;//Math.trunc(betSlipAmount * singleStake);
                if (betType === BETSLIP_TYPE_TEASER && storeBetSlipData.teaserOpts && storeBetSlipData.teaserOpts.length) {
                    const teaserRule = storeBetSlipData.teaserOpts.find(t => t.id === storeBetSlipData.teaserId);
                    const price = teaserRule.price1;
                    const stakeOpts = getRiskAndWin(storeBetSlipData.amountType, betSlipAmount, price);
                    totalRisk = stakeOpts.risk;
                    possibleWin = stakeOpts.win;
                }

                if (storeBetSlipData.wager && storeBetSlipData.slotId > 0) {
                    totalRisk = storeBetSlipData.wager.risk;
                    possibleWin = storeBetSlipData.wager.toWin;
                }

                const amount = Math.min(totalRisk, possibleWin);
                if (betType === BETSLIP_TYPE_PARLAY || betType === BETSLIP_TYPE_ROUNDROBIN) {
                    bet.limit = amount;
                }
                bet.hasWrongStakes = (amount > bet.limit || amount > maxWager || (amount > 0 && amount < minWager));
                bet.hasEmptyStakes = betSlipAmount === 0;

                if (itemIdx > 0 && betType === 1) {
                    totalRisk = (betSlipAmount * multiplier);
                    if (itemIdx === count - 1) {
                        parlayOpts.unshift({ id: 0, label: 'Single' });
                    } else {
                        const combinations = getCombinations(count, itemIdx + 1);
                        if (storeBetSlipData.teaserId > 0 && storeBetSlipData.teaserId === (itemIdx + 1))
                            multiplier = combinations;
                        parlayOpts.push({id: (itemIdx + 1), label: "Round Robin with " + (itemIdx + 1) + "'s (" + combinations +")" });
                    }
                }

                if (itemIdx === count - 1 && betType === 3) {
                    ifBetOpts.push({ id: 0, label: 'If-Bet Win Only' });
                    ifBetOpts.push({ id: 7, label: 'If-Bet Win or Push' });
                    ifBetOpts.push({ id: 8, label: 'If-Bet Action Reverse' });
                }
            }
            const hasValidTeaserSport = (bet.sport === 'NFL' || bet.sport === 'CFB' || bet.sport === 'NBA' || bet.sport === 'CBB');
            const hasValidTeaserPlay = (bet.play === PLAY_TYPE_VISITOR_SPREAD || bet.play === PLAY_TYPE_HOME_SPREAD || bet.play === PLAY_TYPE_TOTAL_OVER || bet.play === PLAY_TYPE_TOTAL_UNDER);
            const integerBase = Math.trunc(bet.initialBase);
            const value = Math.abs(bet.initialBase - integerBase);
            const hasValidParlayPlay = !(value === 1/4 || value === 3/4);

            bet.priceChange = (bet.initialPrice !== bet.currentPrice);
            bet.baseChange = (bet.initialBase !== bet.currentBase);
            info.priceChange = info.priceChange || bet.priceChange;
            info.baseChange = info.baseChange || bet.baseChange;

            info.hasWrongStakes = info.hasWrongStakes || bet.hasWrongStakes;
            info.hasEmptyStakes = info.hasEmptyStakes || bet.hasEmptyStakes;
            info.hasWrongTeaser = (betType === BETSLIP_TYPE_TEASER && (info.hasWrongTeaser || !hasValidTeaserPlay || !hasValidTeaserSport));
            info.hasWrongParlay = (betType === BETSLIP_TYPE_PARLAY && (info.hasWrongParlay || !hasValidParlayPlay));

            events.push(bet);
            itemIdx++;
        });


        if (betType === 0) {
            info.hasWrongStakes = (info.hasWrongStakes && singleItemsOK > 0) ? false : info.hasWrongStakes;
            info.hasEmptyStakes = (info.hasEmptyStakes && singleItemsOK > 0) ? false : info.hasEmptyStakes;
        }
    }

    return { events, info, totalRisk, possibleWin, parlayOpts, ifBetOpts };
}
