import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { changeLanguage, returnCurrentLanguage } from '../index';
import { injectIntl } from 'react-intl';
class Language extends Component {
    static displayName = Language.name;
    
    constructor(props) {
        super(props);
        this.state = {
            isDialogOpen: true
        };
    }

    handleButton1 = () => {
        changeLanguage('es');
        this.handleCloseDialog();
    }

    handleButton2 = () => {
        changeLanguage('en');
        this.handleCloseDialog();
    }

    handleCloseDialog = () => {
        this.setState({ isDialogOpen: false });
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    render() {
        const currentLanguage = returnCurrentLanguage();
        const enDisabled = currentLanguage === "en";
        const esDisabled = currentLanguage === "es";
        const selectLanguage = this.props.intl.formatMessage({ id: 'selectLanguage' })
        const spanish = this.props.intl.formatMessage({ id: 'spanish' })
        const english = this.props.intl.formatMessage({ id: 'english' })
        return (
            <React.Fragment>
                <Modal isOpen={this.state.isDialogOpen} backdrop="static">
                    <ModalHeader>{selectLanguage}</ModalHeader>
                    <ModalBody>
                        <div className="d-flex flex-column">
                            <button className="btn btn-primary mb-2" onClick={this.handleButton1} disabled={esDisabled }>
                                {spanish}
                            </button>

                            <button className="btn btn-primary mb-2" onClick={this.handleButton2} disabled={enDisabled }>
                                {english }
                            </button>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-secondary" onClick={this.handleCloseDialog}>Close</button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        token: state.auth.token,
        userName: state.auth.userName,
        balance: state.balance
    };
}


export default injectIntl(Language);