import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { actionCreators as BetslipActions } from '../store/betslip/actions';
import { Fragment } from 'react';
import { injectIntl } from 'react-intl';

const sumUp = (input, field) => input.reduce(
    (previousValue, currentValue) => previousValue + currentValue[field]
    , 0
);

class Readback extends Component {
    constructor(props) {
        super(props);
        this.state = {
            amount: props.amount,
        }
        this.printTicket = this.printTicket.bind(this);
    }

    printTicket() {
        const mywindow = window.open('', 'PRINT', 'height=400,width=600');
        //https://codepen.io/hasilt/pen/xYBaLY
        //https://parzibyte.me/blog/en/2019/10/10/print-receipt-thermal-printer-javascript-css-html/
        //https://stackoverflow.com/questions/51357990/html-css-print-out-pos-size
        mywindow.document.write('<html><head><title>' + this.props.company.brandName + '</title>');
        mywindow.document.write('<style type="text/css">* {font-size: 12px;font-family: monospace, serif;}*,html {margin: 0;padding: 0;}p{margin-top: 0;margin-bottom: 1rem;}.text-center {text-align: center !important;}.fw-bold {font-weight: 700 !important;}td,th,tr,table {border-top: 1px solid black;border-collapse: collapse;width: 100%;}td.description,th.description {width: 60%;max-width: 60%;}td.quantity,th.quantity {text-align: right;text-align: end;width: 20%;max-width: 20%;word-break: break-all;}td.price,th.price {text-align: right;text-align: end;width: 20%;max-width: 20%;word-break: break-all;}.centered {text-align: center;align-content: center;}</style>');
        mywindow.document.write('</head><body>');
        //mywindow.document.write('<h1>' + this.props.company.brandName + '</h1>');
        mywindow.document.write(document.getElementById("ticketId").innerHTML);
        mywindow.document.write('</body></html>');
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/
        mywindow.print();
        mywindow.close();
        this.props.toggleReadback();
    }

    componentDidMount() {
    }

    render() {
        const wagers = (this.props.readback && this.props.readback.wagers) || [];
        const totalRisk = sumUp(wagers, 'risk');
        const totalWin = sumUp(wagers, 'toWin');
        const receipt = this.props.intl.formatMessage({ id: 'receipt' });
        const upperAccount = this.props.intl.formatMessage({ id: 'upperAccount' });
        const labelDate = this.props.intl.formatMessage({ id: 'printingDate' });
        const upperTotal = this.props.intl.formatMessage({ id: 'upperTotal' });
        const print = this.props.intl.formatMessage({ id: 'print' });
        const continue1 = this.props.intl.formatMessage({ id: 'continue' });
        const description = this.props.intl.formatMessage({ id: 'description' });
        const risk = this.props.intl.formatMessage({ id: 'risk' });
        const win = this.props.intl.formatMessage({ id: 'win' });
        const readback = this.props.intl.formatMessage({ id: 'readback' });

        const readbackHeader1 = this.props.readback && this.props.readback.header1;
        const readbackHeader2 = this.props.readback && this.props.readback.header2;

        const readbackFooter1 = this.props.readback && this.props.readback.footer1;
        const readbackFooter2 = this.props.readback && this.props.readback.footer2;
        
        return (
            <Modal
                fullscreen="sm"
                size=""
                toggle={this.props.toggleReadback}
                isOpen={this.props.isOpen}>
                <ModalHeader toggle={this.props.toggleReadback}>
                    {readback}
                </ModalHeader>
                <ModalBody>
                    <div id="ticketId">
                        <div className="ticket">
                            {readbackHeader1 && <p className="centered fw-bold">{readbackHeader1}</p>}
                            {readbackHeader2 && <p className="centered">{readbackHeader2}</p>}
                            <br />
                            <p className="centered">
                                <strong>{receipt}</strong>
                                <br />
                                {upperAccount} {this.props.info.userName}
                                <br />
                                {labelDate} {new Date().toLocaleString()}
                            </p>
                            <table>
                                <thead>
                                    <tr>
                                        <th className="description">{description}</th>
                                        <th className="quantity">{risk}</th>
                                        <th className="price">{win}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!this.state.loading && this.props.readback && this.props.readback.wagers && this.props.readback.wagers.map((bet) => (
                                        <Fragment key={bet.idWager}>
                                            <tr>
                                                <td className="description">#{bet.idWager} <br />{bet.desc}</td>
                                                <td className="quantity">{this.props.info.currencySymbol}{bet.risk}</td>
                                                <td className="price">{this.props.info.currencySymbol}{bet.toWin}</td>
                                            </tr>
                                            {bet.items.map((item, itemIdx) => (
                                                <Fragment key={bet.idWager + '_' + itemIdx}>
                                                    <tr>
                                                        <td className="description" colSpan="3">
                                                            <p>{item.desc} {item.idSport}</p>
                                                            <p>{labelDate} {item.gameDate}</p>
                                                        </td>
                                                    </tr>
                                                </Fragment>
                                            ))}
                                            <tr>
                                                <td className="description" colSpan="3"><hr/></td>
                                            </tr>
                                        </Fragment>
                                    ))}
                                    <tr>
                                        <td className="description">{upperTotal}</td>
                                        <td className="quantity">{this.props.info.currencySymbol}{totalRisk}</td>
                                        <td className="price">{this.props.info.currencySymbol}{totalWin}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                            {readbackFooter1 && <p className="centered fw-bold">{readbackFooter1}</p>}
                            {readbackFooter2 && <p className="centered">{readbackFooter2}</p>}
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.printTicket}>
                        {print}
                    </Button>
                    {' '}
                    <Button onClick={this.props.toggleReadback}>
                        {continue1}
                    </Button>
                </ModalFooter>
            </Modal>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        readback: state.betslip.readback,
        company: state.config,
        info: state.auth
    }
}

const mapDispatchToProps = {
    removeItem: BetslipActions.removeItem,
    clearShoppingCart: BetslipActions.clear
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Readback));