import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { actionCreators } from '../store/auth/actions';
import { injectIntl } from 'react-intl';


const sumUp = (input, field) => input.reduce(
    (previousValue, currentValue) => previousValue + currentValue[field]
    , 0
);

const WeekSummaryView = ({ history, loading, winLoss, cashInOut, casino, horses, balance }) => (
    <table className="responsive-table">

        <tbody>
            {loading && (
                <tr className="table-row3">
                    <td colSpan="9">Loading...</td>
                </tr>
            )}
            {!loading && history.length && (
                <Fragment>
                    <tr className="table-row3">
                        <td data-label={winLoss}>{winLoss}</td>
                        <td className="empty-cell"></td>
                        {history.map((item, index) => (
                            <td key={index} data-label={item.winLossAmount}>{item.winLossAmount}</td>
                        ))}
                        <td data-label="Total">{sumUp(history, 'winLossAmount')}</td>
                    </tr>
                    <tr className="table-row3">
                        <td data-label={cashInOut}>{cashInOut}</td>
                        <td className="empty-cell"></td>
                        {history.map((item, index) => (
                            <td key={index} data-label={item.cashInOut}>{item.cashInOut}</td>
                        ))}
                        <td data-label="Total">{sumUp(history, 'cashInOut')}</td>
                    </tr>
                    <tr className="table-row3">
                        <td data-label={casino}>{casino}</td>
                        <td className="empty-cell"></td>
                        {history.map((item, index) => (
                            <td key={index} data-label={item.casino}>{item.casino}</td>
                        ))}
                        <td data-label="Total">{sumUp(history, 'casino')}</td>
                    </tr>
                    <tr className="table-row3">
                        <td data-label={horses}>{horses}</td>
                        <td className="empty-cell"></td>
                        {history.map((item, index) => (
                            <td key={index} data-label={item.horses}>{item.horses}</td>
                        ))}
                        <td data-label="Total">{sumUp(history, 'horses')}</td>
                    </tr>
                    <tr className="table-row3">
                        <td data-label={balance}>{balance}</td>
                        <td>0</td>
                        {history.map((item, index) => (
                            <td key={index} data-label={item.balance}>{item.balance}</td>
                        ))}
                        <td data-label="Total">{sumUp(history, 'winLossAmount') + sumUp(history, 'cashInOut')}</td>
                    </tr>
                </Fragment>
            )}
        </tbody>
    </table>
);


const ItemView = ({ order, item, index }) => {
    if (index === 0)
        return (
            <tbody>
                <tr className="table-row3">
                    <td>{order.userName}</td>
                    <td className="empty-cell"></td>
                    <td className="empty-cell"></td>
                    <td>{order.desc}</td>
                    <td>{order.risk}/{order.toWin}</td>
                    <td>{order.winLoss}</td>
                    <td>{order.outcome}</td>
                    <td>{order.placedDate}</td>
                </tr>
                <tr className="table-row3">
                    <td className="empty-cell"></td>
                    <td>{item.gameDate}</td>
                    <td>{item.idSport}</td>
                    <td>{item.desc}</td>
                    <td className="empty-cell"></td>
                    <td className="empty-cell"></td>
                    <td>{item.outcome}</td>
                    <td className="empty-cell"></td>
                </tr>
            </tbody>
        );

    return (
        <tr>
            <td className="empty-cell"></td>
            <td>{item.gameDate}</td>
            <td>{item.idSport}</td>
            <td>{item.desc}</td>
            <td className="empty-cell"></td>
            <td className="empty-cell"></td>
            <td>{item.outcome}</td>
            <td className="empty-cell"></td>
        </tr>
    );
};


const OrderSummaryView = ({ order }) => {
    if (order.items.length === 1)
        return (
            <table className="responsive-table">
                <thead>
                    <tr className='borderless-table' style={{ borderTop: '1px solid white' }}></tr>
                </thead>
                <tbody>
                    <Fragment>
                        <tr className='table-row3'>
                            <td>{order.userName}</td>
                            <td>Ticket#: {order.ticketNumber}</td>
                            <td>{order.items[0].idSport}</td>
                            <td>{order.desc}</td>
                            <td>{order.risk}/{order.toWin}</td>
                            <td>{order.winLoss}</td>
                            <td>{order.outcome}</td>
                            <td>{order.placedDate}</td>
                        </tr>
                        <tr className='table-row3'>
                            <td className="empty-cell"></td>
                            <td>{order.items[0].gameDate}</td>
                            <td>{order.items[0].idSport}</td>
                            <td>{order.items[0].desc}</td>
                            <td className="empty-cell"></td>
                            <td className="empty-cell"></td>
                            <td>{order.items[0].outcome}</td>
                            <td className="empty-cell"></td>
                        </tr>
                    </Fragment>
                </tbody>
            </table>

        );

    return (
        <Fragment>
            <li className="table-row3">
                {order.items.map((item, index) => (<ItemView key={`${order.idWager}_${index}`} order={order} item={item} index={index} />))}
            </li>
        </Fragment>);
};

const DAY_UNIT = 1000 * 60 * 60 * 24;

const padTo2Digits = (num) => num.toString().padStart(2, '0');
const formatDate = (date) => (
    [
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
        date.getFullYear(),
    ].join('/')/* +
    ' ' +
    [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        padTo2Digits(date.getSeconds()),
    ].join(':')*/
);

const getStartingPoint = () => {
    const now = new Date();
    const dayOfWeekDigit = now.getDay();
    let factor = (dayOfWeekDigit > 1) ? (1 - dayOfWeekDigit) : 0;
    now.setHours(0, 0, 0, 0);
    const fromDate = new Date(now.getTime() + (DAY_UNIT * factor));
    fromDate.setHours(0, 0, 0, 0);

    return fromDate.getTime();
}


class SettledBets3 extends Component {
    static displayName = SettledBets3.name;

    constructor(props) {
        super(props);
        const startingPoint = getStartingPoint();

        this.state = {
            history: [],
            mode: 0,
            startingPoint: startingPoint,
            fromDate: new Date(startingPoint),
            toDate: new Date(startingPoint + (DAY_UNIT * 6)),
            loading: true,
            isMobile: window.innerWidth < 768,
            dropdownOpen: false
        };
        this.toggleSettleMode = this.toggleSettleMode.bind(this);
        this.toggleDropdown = this.toggleDropdown.bind(this);
    }

    toggleDropdown() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    handleResize = () => {
        const isMobile = window.innerWidth < 768;
        this.setState({ isMobile });
    }

    componentDidMount() {
        this.populateCategoryData(this.state.mode);
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    toggleSettleMode(mode) {
        const startingPoint = getStartingPoint();
        const fromDate = new Date(startingPoint + (DAY_UNIT * -mode * 7));
        this.setState(prevState => ({
            mode: mode,
            startingPoint: startingPoint,
            fromDate: fromDate,
            toDate: new Date(fromDate.getTime() + (DAY_UNIT * 6)),
            loading: true
        }));
        this.populateCategoryData(mode);
    }

    render() {
        const { isMobile } = this.state;
        const viewThisWeek = this.props.intl.formatMessage({ id: 'viewThisWeek' });
        const viewLastWeek = this.props.intl.formatMessage({ id: 'viewLastWeek' });
        const view2Week = this.props.intl.formatMessage({ id: 'view2Week' });
        const view3Week = this.props.intl.formatMessage({ id: 'view3Week' });
        const view4Week = this.props.intl.formatMessage({ id: 'view4Week' });
        const view5Week = this.props.intl.formatMessage({ id: 'view5Week' });
        const view6Week = this.props.intl.formatMessage({ id: 'view6Week' });
        const view7Week = this.props.intl.formatMessage({ id: 'view7Week' });
        const historyFor = this.props.intl.formatMessage({ id: 'historyFor' });
        const from = this.props.intl.formatMessage({ id: 'from' });
        const to = this.props.intl.formatMessage({ id: 'to' });
        const begginningWeek = this.props.intl.formatMessage({ id: 'begginningWeek' });
        const mon = this.props.intl.formatMessage({ id: 'mon' });
        const tue = this.props.intl.formatMessage({ id: 'tue' });
        const wed = this.props.intl.formatMessage({ id: 'wed' });
        const thu = this.props.intl.formatMessage({ id: 'thu' });
        const fri = this.props.intl.formatMessage({ id: 'fri' });
        const sat = this.props.intl.formatMessage({ id: 'sat' });
        const sun = this.props.intl.formatMessage({ id: 'sun' });
        const total = this.props.intl.formatMessage({ id: 'total' });
        const userPhone = this.props.intl.formatMessage({ id: 'userPhone' });
        const gameDate = this.props.intl.formatMessage({ id: 'gameDate' });
        const sport = this.props.intl.formatMessage({ id: 'sport' });
        const description = this.props.intl.formatMessage({ id: 'description' });
        const riskWin = this.props.intl.formatMessage({ id: 'riskWin' });
        const winLossAmount = this.props.intl.formatMessage({ id: 'winLossAmount' });
        const result = this.props.intl.formatMessage({ id: 'result' });
        const datePlaced = this.props.intl.formatMessage({ id: 'datePlaced' });
        const cashInOut = this.props.intl.formatMessage({ id: 'cashInOut' });
        const winLoss = this.props.intl.formatMessage({ id: 'winLoss' });
        const balance = this.props.intl.formatMessage({ id: 'balance' });
        const casino = this.props.intl.formatMessage({ id: 'casino' });
        const horses = this.props.intl.formatMessage({ id: 'horses' });
        return (
            <Fragment>

                {isMobile ? (
                    <div className="text-center">
                        <div className="dropdown d-inline-block">
                            <button className="btn btn-outline-primary dropdown-toggle" type="button" id="weekDropdownButton" onClick={this.toggleDropdown}>
                                Select Week
                            </button>
                            <ul className={"dropdown-menu text-center" + (this.state.dropdownOpen ? " show" : "")} aria-labelledby="weekDropdownButton">
                                <li>
                                    <button className="dropdown-item" onClick={() => this.toggleSettleMode(0)}>{viewThisWeek}</button>
                                </li>
                                <li>
                                    <button className="dropdown-item" onClick={() => this.toggleSettleMode(1)}>{viewLastWeek}</button>
                                </li>
                                <li>
                                    <button className="dropdown-item" onClick={() => this.toggleSettleMode(2)}>{view2Week}</button>
                                </li>
                            </ul>
                        </div>
                    </div>



                ) : (
                    <div className="btn-group p-2 col-12 d-flex flex-wrap justify-content-left">
                        <div className="mb-2">
                            <Button className="custom-button btn-block" onClick={() => this.toggleSettleMode(0)}>
                                {viewThisWeek}
                            </Button>
                        </div>
                        <div className="mb-2">
                            <Button className="custom-button btn-block" onClick={() => this.toggleSettleMode(1)}>
                                {viewLastWeek}
                            </Button>
                        </div>
                        <div className="mb-2">
                            <Button className="custom-button btn-block" onClick={() => this.toggleSettleMode(2)}>
                                {view2Week}
                            </Button>
                        </div>
                        
                        
                    </div>
                )}

                <div className="text-left">
                    <h4>{historyFor}{this.props.userName}</h4>
                </div>
                <div className="text-left">
                    <h6>{from} {formatDate(this.state.fromDate)} {to} {formatDate(this.state.toDate)}</h6>
                </div>

                <div className="card-body">
                    <div className="container2">

                        <ul className="responsive-table">
                            <li className="table-header3">
                                <div></div>
                                <div>{begginningWeek}</div>
                                <div>{mon}</div>
                                <div>{tue}</div>
                                <div>{wed}</div>
                                <div>{thu}</div>
                                <div>{fri}</div>
                                <div>{sat}</div>
                                <div>{sun}</div>
                                <div>{total}</div>
                            </li>
                            <WeekSummaryView
                                history={this.state.history}
                                loading={this.state.loading}
                                winLoss={winLoss}
                                cashInOut={cashInOut}
                                casino={casino}
                                horses={horses}
                                balance={balance}
                            />
                        </ul>
                    </div>
                </div>

                <div className="card-body">
                    <div className="container2">

                        <ul className="responsive-table">
                            <li className="table-header3">
                                <div>{userPhone}</div>
                                <div>{gameDate}</div>
                                <div>{sport}</div>
                                <div>{description}</div>
                                <div>{riskWin}</div>
                                <div>{winLossAmount}</div>
                                <div>{result}</div>
                                <div>{datePlaced}</div>
                            </li>
                            {this.state.history.map((history, sequence) => {
                                return (
                                    <Fragment key={sequence}>
                                        {history.orders.map((order, index) => (
                                            <OrderSummaryView key={`${sequence}_${index}`} order={order} />
                                        ))}
                                    </Fragment>
                                );
                            })}
                        </ul>

                    </div>
                </div>



            </Fragment>
        );
    }

    async populateCategoryData(mode) {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `bearer ${this.props.token}`
            }
        };

        const response = await fetch(`api/v1/players/${mode}/settled`, requestOptions);
        if (response.ok) {
            const data = await response.json();
            this.setState({ history: data, loading: false });
        } else {
            if (response.status === 401) {
                this.props.logout();
            }
            this.setState({ loading: false });
        }
    }
}


function mapStateToProps(state) {
    return {
        token: state.auth.token,
        userName: state.auth.userName
    };
}

const mapDispatchToProps = {
    logout: actionCreators.resetState
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SettledBets3));