import React, { Component, Fragment } from 'react';
import { Container } from 'reactstrap';
import { Navigate, Outlet } from 'react-router-dom';
import { connect } from 'react-redux';
import withRouter from "../hooks/withRouter";
import NavMenu2 from '../components/NavMenu2';
import Readback from '../components/Readback';
import CustomerSettings from '../components/CustomerSettings';
import Loader from "../components/loader/";
import { actionCreators as BetslipActions } from '../store/betslip/actions';
import { ActionCreators as ConfigActions } from '../store/config/actions';

class Layout2 extends Component {
    static displayName = Layout2.name;

    componentDidMount() {
        document.body.className = "fullpage"; // Or set the class
    }

    render() {
        if (!this.props.appReady) {
            return <Loader />;
        }

        if (!this.props.isAuthenticated && this.props.location.pathname.indexOf('signin') === -1) {
            return <Navigate to="/signin" />;
        }

        return (
            <Fragment>
                <NavMenu2 />
                {this.props.hasSportsEnabled &&
                    <>
                        <Container fluid>
                            <Outlet />
                        </Container>
                        <Readback isOpen={this.props.readbackOpen} toggleReadback={this.props.toggleReadback} />
                        <CustomerSettings isOpen={this.props.settingsOpen} toggleSettingsMenu={this.props.toggleSettingsMenu} />
                    </>
                }
                {!this.props.hasSportsEnabled &&
                    <main>
                        <div className="loading-spinner">
                            <div className="spinner"></div>
                        </div>
                    </main>
                }
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        hasSportsEnabled: (state.auth.flags & 1) > 0,
        readbackOpen: state.betslip.readbackOpen,
        readbacks: state.betslip.readbacks,
        appReady: state.config.appReady,
        settingsOpen: state.config.settingsOpen
    };
}

const mapDispatchToProps = {
    toggleReadback: BetslipActions.toggleReadback,
    toggleSettingsMenu: ConfigActions.toggleSettingsMenu
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Layout2));