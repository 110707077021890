import { AuthActionType, AuthStatusEnum } from './types';

const initialState = {
    token: '',
    userName: '',
    isAuthenticated: false,
    flags: 0,
    currencySymbol: '$',
    languageId: 0,
    skinId: 0,
    colorId: 0,
    status: AuthStatusEnum.NONE
};

const AuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case AuthActionType.SET_AUTH_STATUS: {
            return {
                ...state,
                status: action.payload.status
            };
        }
        case AuthActionType.LOGIN_SUCCESS: {
            const authUser = action.payload;

            return {
                ...authUser,
                isAuthenticated: true,
                minWager: 0,
                maxWager: 0,
                flags: 0,
                currencySymbol: '',
                languageId: 0,
                skinId: 0,
                colorId: 0,
                status: AuthStatusEnum.SUCCESS
            };
        }
        case AuthActionType.CONNECTION_FAIL:
            return {
                ...initialState,
                status: AuthStatusEnum.FATAL
            };
        case AuthActionType.SET_AUTH_INFO:
        case 'betslip/setBookStake':
            return {
                ...state,
                ...action.payload
            };
        case AuthActionType.LOGIN_FAIL:
            return {
                ...initialState,
                status: AuthStatusEnum.FAIL
            };
        case AuthActionType.LOGOUT:
        case AuthActionType.RESET_STATE: {
            return {
                ...initialState
            };
        }
        case AuthActionType.LOGIN:
        default:
            return state;
    }
};

export default AuthReducer;