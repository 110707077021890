import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { changeSkin, returnSkin } from '../index';
import { injectIntl } from 'react-intl';
class ChooseSkin extends Component {
    static displayName = ChooseSkin.name;

    constructor(props) {
        super(props);
        this.state = {
            isDialogOpen: true
        };
    }

    handleButton1 = () => {
        changeSkin('red');
        this.handleCloseDialog();
    }

    handleButton2 = () => {
        changeSkin('');
        this.handleCloseDialog();
    }

    handleCloseDialog = () => {
        this.setState({ isDialogOpen: false });
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    render() {
        const currentLanguage = returnSkin();
        const enDisabled = currentLanguage === "red";
        const esDisabled = currentLanguage === "";
        const selectSkin = this.props.intl.formatMessage({ id: 'selectSkin' })
        const firstSkin = this.props.intl.formatMessage({ id: 'firstSkin' })
        const secondSkin = this.props.intl.formatMessage({ id: 'secondSkin' })
        return (
            <React.Fragment>
                <Modal isOpen={this.state.isDialogOpen} backdrop="static">
                    <ModalHeader>{selectSkin}</ModalHeader>
                    <ModalBody>
                        <div className="d-flex flex-column">
                            <button className="btn btn-primary mb-2" onClick={this.handleButton1} disabled={enDisabled }>
                                {firstSkin}
                            </button>

                            <button className="btn btn-primary mb-2" onClick={this.handleButton2} disabled={esDisabled }>
                                {secondSkin}
                            </button>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-secondary" onClick={this.handleCloseDialog}>Close</button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        token: state.auth.token,
        userName: state.auth.userName,
        balance: state.balance
    };
}


export default injectIntl(ChooseSkin);