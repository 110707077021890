import React from 'react';

import PreMatch from "./containers/PreMatch";
import PreMatch2 from "./containers/PreMatch2";
import PreMatch3 from "./containers/PreMatch3";
import Account from "./containers/Account";
import Account2 from "./containers/Account2";
import SettledBets from "./containers/SettledBets";
import SettledBets2 from "./containers/SettledBets2";
import SettledBets3 from "./containers/SettledBets3";
import UnsettledBets from "./containers/UnsettledBets";
import UnsettledBets2 from "./containers/UnsettledBets2";
import UnsettledBets3 from "./containers/UnsettledBets3";
import Signin from "./containers/Signin";
import Layout from "./containers/Layout";
import Layout2 from "./containers/Layout2";
import Layout3 from "./containers/Layout3";
import LiveBetting from "./containers/LiveBetting";
import CustomWindow from "./containers/CustomWindow";
import Colors from "./containers/Colors";
import Language from "./containers/Language";


const AppRoutes = (skin) => {
    let routes;

    if (skin === 'red') {
        routes = [
            {
                path: "signin",
                element: <Signin />
            },
            {
                path: "*",
                element: <Layout2 />,
                children: [
                    { path: "", element: <PreMatch2 /> },
                    { path: "account", element: <Account2 /> },
                    { path: "account/settled", element: <SettledBets2 /> },
                    { path: "account/unsettled", element: <UnsettledBets2 /> },
                    { path: "live-betting", element: <LiveBetting /> },
                    { path: "custom-window", element: <CustomWindow />},
                    { path: "colors", element: <Colors /> },
                    { path: "language", element: <Language /> }
                ],
            },
        ];
    } else if (skin === "velocity") {
        routes = [
            {
                path: "signin",
                element: <Signin />
            },
            {
                path: "*",
                element: <Layout3 />,
                children: [
                    { path: "", element: <PreMatch3 /> },
                    { path: "account", element: <Account /> },
                    { path: "account/settled", element: <SettledBets3 /> },
                    { path: "account/unsettled", element: <UnsettledBets3 /> },
                    { path: "live-betting", element: <LiveBetting /> },
                    { path: "custom-window", element: <CustomWindow /> },
                    { path: "colors", element: <Colors /> },
                    { path: "language", element: <Language /> }
                ],
            },
        ];
    } else {
        routes = [
            {
                path: "signin",
                element: <Signin />
            },
            {
                path: "*",
                element: <Layout />,
                children: [
                    { path: "", element: <PreMatch /> },
                    { path: "account", element: <Account /> },
                    { path: "account/settled", element: <SettledBets /> },
                    { path: "account/unsettled", element: <UnsettledBets /> },
                    { path: "live-betting", element: <LiveBetting /> },
                    { path: "custom-window", element: <CustomWindow /> },
                    { path: "colors", element: <Colors /> },
                    { path: "language", element: <Language /> }
                ],
            },
        ];
    }

    return routes;
};

export default AppRoutes;
