import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import withRouter from '../hooks/withRouter';
import { injectIntl } from 'react-intl';
import { Accordion, AccordionItem, AccordionHeader, AccordionBody } from 'reactstrap';

class AccountTab2 extends Component {
    static displayName = AccountTab2.name;

    constructor(props) {
        super(props);
        this.state = {
            openSection: ''
        };
    }

    toggleSection = (id) => {
        this.setState({ openSection: this.state.openSection === id ? '' : id });
    }

    render() {
        const figures = this.props.intl.formatMessage({ id: 'figures' });
        const settledBets = this.props.intl.formatMessage({ id: 'settledBets' });
        const unsettledBets = this.props.intl.formatMessage({ id: 'unsettledBets' });
        const accountSections = [
            {
                label: figures,
                path: '/account'
            },
            {
                label: settledBets,
                path: '/account/settled'
            },
            {
                label: unsettledBets,
                path: '/account/unsettled'
            }
        ];
        const { openSection } = this.state;
        const reports = this.props.intl.formatMessage({ id: 'reports' });
        return (
            <article className="account-tab">
                <Accordion open={openSection} toggle={this.toggleSection} className = "accordion2 my-4">
                    <AccordionItem className = "accordion2-item">
                        <AccordionHeader targetId="1" className = "accordion2-header">{reports}</AccordionHeader>
                        <AccordionBody accordionId="1" className = "accordion2-body">
                            {accountSections.map((section) => (
                                <div key={section.path}>
                                    <Link to={section.path} className="btn btn-secondary">
                                        {section.label}
                                    </Link>
                                </div>
                            ))}
                        </AccordionBody>
                    </AccordionItem>
                </Accordion>
            </article>
        );
    }
}

export default withRouter(injectIntl(AccountTab2));
