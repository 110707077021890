import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import withRouter from '../hooks/withRouter';
import { injectIntl } from 'react-intl';


class AccountTab extends Component {
    static displayName = AccountTab.name;
    
    render() {
        const figures = this.props.intl.formatMessage({ id: 'figures' });
        const settledBets = this.props.intl.formatMessage({ id: 'settledBets' });
        const unsettledBets = this.props.intl.formatMessage({ id: 'unsettledBets' });
        const accountSections = [
            {
                label: figures,
                path: '/account'
            },
            {
                label: settledBets,
                path: '/account/settled'
            },
            {
                label: unsettledBets,
                path: '/account/unsettled'
            }
        ];
        const selectedKey = this.props.location.pathname;
        return (
            <article className="account-tab">
                <div className="nav nav-tabs nav-fill">
                    {accountSections.map((section) => (
                        <Link to={section.path} className={'nav-item nav-link ' + (selectedKey === section.path ? 'active' : '')} key={section.path}>
                            {section.label}
                        </Link>
                    ))
                    }
                </div>
            </article>
        );
    }
}

export default withRouter(injectIntl(AccountTab));