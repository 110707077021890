import React, { Component, Fragment } from 'react';
import { Button } from 'reactstrap';
import { RawHtml } from './RawHtml';
import { getSelectionOffer, getSelectionBidOffer, calculateRiskOrWin } from '../helpers/betslip';
import { injectIntl } from 'react-intl';
class BetslipItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            betType: props.betType,
            risk: props.item.singleRisk,
            win: props.item.singleWin,
            bidIdx: props.item.bidIdx
        }
        this.onRiskChange = this.onRiskChange.bind(this);
        this.onWinChange = this.onWinChange.bind(this);;
        this.onBidPriceChange = this.onBidPriceChange.bind(this);
        this.onLineAdjChange = this.onLineAdjChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.setBetAmount = this.setBetAmount.bind(this);
    }

    onLineAdjChange = (e) => {
        const selection = +e.target.value;
        switch (selection) {
            case 0:
                let win = this.props.item.singleWin, risk = this.props.item.singleRisk;
                const bidBase = +this.props.item.currentBase;
                const bidPrice = +this.props.item.currentPrice;
                if (risk > win) {
                    risk = calculateRiskOrWin(-bidPrice, win);
                } else {
                    win = calculateRiskOrWin(bidPrice, risk);
                }

                this.setState(prevState => ({
                    win,
                    risk,
                    bidIdx: 0 //Undo line change
                }));
                this.props.itemStakeChange(this.props.item, { win, risk, currentPrice: bidPrice, currentBase: bidBase });
                break;
            case 1:
                this.props.removeItem(this.props.item);
                break;
            case 2:
                this.props.clearShoppingCart();
                break;
        }
    }

    onRiskChange = (e) => {
        const { bidPrice } = this.props.item;
        const risk = +e.target.value;
        const win = calculateRiskOrWin(bidPrice, risk);
        this.setState(prevState => ({
            win,
            risk
        }));
        this.props.itemStakeChange(this.props.item, { win, risk });
    }

    onWinChange = (e) => {
        const { bidPrice } = this.props.item;
        const win = +e.target.value;
        const risk = calculateRiskOrWin(-bidPrice, win);
        this.setState(prevState => ({
            win,
            risk
        }));
        this.props.itemStakeChange(this.props.item, { win, risk});
    }

    onBidPriceChange = (e) => {
        let win = this.props.item.singleWin, risk = this.props.item.singleRisk;
        const bidIdx = +e.target.value;
        const bid = this.props.item.offers[bidIdx];
        const tokens = bid.split('_');
        const bidBase = +tokens[0];
        const bidPrice = +tokens[2];
        if (risk > win) {
            risk = calculateRiskOrWin(-bidPrice, win);
        } else {
            win = calculateRiskOrWin(bidPrice, risk);
        }

        this.setState(prevState => ({
            win,
            risk,
            bidIdx
        }));
        this.props.itemStakeChange(this.props.item, { win, risk, bidPrice, bidBase });
    }

    setBetAmount = (e) => {
        const { bidPrice } = this.props.item;
        let win = this.props.item.singleWin, risk = this.props.item.singleRisk;
        if (bidPrice < 0) {
            win = +e;
            risk = calculateRiskOrWin(-bidPrice, win);
        } else {
            risk = +e;
            win = calculateRiskOrWin(bidPrice, risk);
        }
        this.setState(prevState => ({
            win,
            risk
        }));
        this.props.itemStakeChange(this.props.item, { win, risk });
    }

    onSubmit = (e) => {
        if (e.key === "Enter") {
            this.props.handleSubmit();
        }
    }

    render() {
        const { item, itemIndex, removeItem, moveItemUpward, currency, isFinal, betType } = this.props;
        const risk = +this.props.item.singleRisk;
        const win = +this.props.item.singleWin
        const maxWager = Math.min(+item.limit, +this.props.maxWager);

        const lineChanged = this.props.intl.formatMessage({ id: 'lineChanged' });
        const selectYourOption = this.props.intl.formatMessage({ id: 'selectYourOption' });
        const acceptChange = this.props.intl.formatMessage({ id: 'acceptChange' });
        const discardThisSelection = this.props.intl.formatMessage({ id: 'discardThisSelection' });
        const discardAllSelections = this.props.intl.formatMessage({ id: 'discardAllSelections' });
        const toWin = this.props.intl.formatMessage({ id: 'toWin' });
        const risk1 = this.props.intl.formatMessage({ id: 'risk' });
        const minBet = this.props.intl.formatMessage({ id: 'minBet' });
        const maxBet = this.props.intl.formatMessage({ id: 'maxBet' });
        const loading = this.props.intl.formatMessage({ id: 'loading...' });

        return (
            <li className="list-group-item py-1 px-0">
                {item.processing &&
                    <div className="spinner-grow" role="status">
                        <span className="visually-hidden">{loading}</span>
                    </div>
                }

                <div className="row g-0 custom-scrollbar">
                    {!isFinal &&
                        <div className="col-auto me-2">
                            <button className="btn btn-sm btn-outline-danger d-block">
                                <i className="material-icons" onClick={() => removeItem(item)}>delete</i>
                            </button>
                            {(betType === 3 && itemIndex > 0) &&
                                <button className="btn btn-sm btn-outline-primary d-block mt-2">
                                    <i className="material-icons" onClick={() => moveItemUpward(item)}>arrow_upward</i>
                                </button>
                            }
                        </div>
                    }
                    <div className="col">
                        <div className="row g-0">
                            <div className="col">
                                <span className="fw-bold">{item.rot}&nbsp;{item.selection}</span>
                            </div>
                            <div className="col-auto">
                                {item.offers.length === 0 && getSelectionOffer(item, undefined, betType)}
                                {item.offers.length > 0 &&
                                    <select className="form-select form-select-sm" aria-label="Buy Points" name='bidIdx' value={this.state.bidIdx} onChange={this.onBidPriceChange}>
                                        {item.offers.map((opts, index) => {
                                            return <option value={index} key={index}>{getSelectionBidOffer(item, opts)}</option>
                                        })}
                                    </select>
                                }
                            </div>
                            <div className="col-12">
                                {item.title &&
                                    <Fragment>
                                        <small className="text-muted">{item.title}</small>
                                        <br />
                                    </Fragment>
                                }
                                <small>
                                    <RawHtml>{item.competition}</RawHtml>
                                </small>
                            </div>
                            {item.notes &&
                                <div className="col-12">
                                    <small className="text-muted">
                                        <RawHtml>{item.notes}</RawHtml>
                                    </small>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {item.errorMsg &&
                    <div className="row g-0">
                        <div className="alert alert-danger" role="alert">
                            <RawHtml>{item.errorMsg}</RawHtml>
                        </div>
                    </div>
                }
                {(item.priceChange || item.baseChange) &&
                    <div className="row g-0 text-center">
                        <div className="col-6">
                            <span className="badge bg-warning">{lineChanged}</span><br />
                            <span className="fw-bold">{getSelectionBidOffer(item, `0_${item.currentBase}_${item.currentPrice}`)}</span>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <div>
                                    <button
                                        type="button"
                                        className="btn btn-outline-primary btn-sm"
                                        name="changeType"
                                        value="0"
                                        onClick={this.onLineAdjChange}>
                                        {acceptChange}
                                    </button>
                                </div>
                                <div>
                                    <button
                                        type="button"
                                        className="btn btn-outline-danger btn-sm"
                                        name="changeType"
                                        value="2"
                                        onClick={this.onLineAdjChange}>
                                        {discardAllSelections}
                                    </button>
                                </div>
                            </div>


                        </div>
                    </div>
                }
                {(betType === 0) &&
                    <div className="row g-0 text-center">
                        <div className="col-12">
                            <div className={'input-group input-group-sm mb-0 ' + (item.hasWrongStakes ? 'is-invalid' : '')}>
                                <span className="input-group-text">{risk1}</span>
                                {isFinal && <span className="input-group-text fw-bold">{risk}&nbsp;{currency}</span>}
                                {!isFinal &&
                                    <input name='risk' type="number" aria-label="Risk" className="form-control" placeholder="Risk" value={risk.toString()}
                                        onKeyPress={event => this.onSubmit(event)}
                                        onChange={this.onRiskChange} autoComplete="off" min="0" step="1" pattern="^[0-9]+" />
                                }
                                <span className="input-group-text">{toWin}</span>
                                {isFinal && <span className="input-group-text fw-bold">{win}&nbsp;{currency}</span>}
                                {!isFinal &&
                                    <input name='win' type="number" aria-label="To Win" className="form-control" placeholder="To Win" value={win.toString()}
                                        onKeyPress={event => this.onSubmit(event)}
                                        onChange={this.onWinChange} autoComplete="off" min="0" step="1" pattern="^[0-9]+" />
                                }
                            </div>
                        </div>
                        <div className="col-12">
                            <Button color="link" size="sm" onClick={() => this.setBetAmount(this.props.minWager)}>
                                <small className="text-muted">{minBet}:&nbsp;{this.props.minWager}&nbsp;{currency}</small>
                            </Button>
                            <Button color="link" size="sm" onClick={() => this.setBetAmount(maxWager)}>
                                <small className="text-muted">{maxBet}:&nbsp;{maxWager}&nbsp;{currency}</small>
                            </Button>
                        </div>
                    </div>
                }
            </li>

        );
    }
}

export default injectIntl(BetslipItem);