import { AuthActionType, AuthStatusEnum, STORAGE_KEY } from './types';
import { changeLanguage } from '../../index';

export const actionCreators = {
    resetState: () => async (dispatch) => {
        localStorage.removeItem(STORAGE_KEY);
        dispatch({
            type: 'betslip/clear'
        });
        dispatch ({
            type: AuthActionType.RESET_STATE
        });
    },
    setAuthStatus: (status) => {
        return ({
            payload: { status },
            type: AuthActionType.SET_AUTH_STATUS,
        });
    },
    setLanguage: (languageId) => async (dispatch, getState) => {
        const token = getState().auth.token;
        const settingsRequest = {
            Id: 0,
            LanguageId: languageId
        };

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            },
            body: JSON.stringify(settingsRequest)
        };

        try {
            
            const response = await fetch(`api/v1/players/0/language`, requestOptions);
            if (response.ok) {
                const info = await actionCreators.fetchInfoUnwrapped(token);
                //console.log('Respuesta del servidor:', info);
                if (info) {
                    dispatch({
                        type: 'betslip/setBookStake',
                        payload: {
                            minWager: info.minWager,
                            maxWager: info.maxWager,
                            flags: info.flags,
                            currencySymbol: info.currencySymbol,
                            languageId: info.languageId
                        }
                    });

                    if (info.languageId === 1)
                        changeLanguage('es');
                    else
                        changeLanguage('en');
                }
            } else {
                if (response.status === 401) {
                    this.props.logout();
                }
                this.setState({ loading: false });
            }
        } catch (infoError) {
            console.log(infoError);
        }
    },
    setSkin: (skinId) => async (dispatch, getState) => {
        const token = getState().auth.token;
        const settingsRequest = {
            Id: 0,
            SkinId: skinId
        };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(settingsRequest)
        };

        try {
            const response = await fetch('api/v1/players/0/skin', requestOptions);
            if (response.ok) {
                
            } else {
                if (response.status === 401) {
                    this.props.logout();
                } else {
                    console.error(`Error: ${response.statusText}`);
                }
            }
        } catch (infoError) {
            console.error('Error al establecer skin:', infoError);
        }
    },
    setColor: (colorId) => async (dispatch, getState) => {
        const token = getState().auth.token; 
        const settingsRequest = {
            Id: 0,
            ColorId: colorId
        };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            },
            body: JSON.stringify(settingsRequest)
        };

        try {
            const response = await fetch('api/v1/players/1/color', requestOptions);


            if (!response.ok) {
                if (response.status === 401) {
                    this.props.logout();
                }
                console.error(`Error: ${response.statusText}`);
            }
        } catch (infoError) {
            console.log(infoError);
        }
    },
    login: (credentials) => async (dispatch) => {
        try {
            dispatch({
                type: AuthActionType.SET_AUTH_STATUS,
                payload: { status: AuthStatusEnum.PROCESS }
            });

            let maxRetries = credentials.retryPassword;
            let retryCount = 0;
            let authUser = undefined;
            const origin = credentials.username;
            let username = credentials.username;
            let password = credentials.password;
            const suffix = '.';

            while (retryCount < maxRetries && !authUser) {
                authUser = await actionCreators.loginUnwrapped({ username, password });

                if (!authUser && maxRetries > 1) {
                    username = origin + suffix.padStart((retryCount+1), '.');
                }

                retryCount++;
            }

            if (authUser && authUser?.accessToken) {
                const success = true;
                const payload = success ? {
                    token: authUser?.accessToken,
                    userName: authUser?.userName
                } : undefined;

                dispatch({
                    type: success ? AuthActionType.LOGIN_SUCCESS : AuthActionType.LOGIN_FAIL,
                    payload
                });
                
                if (success) {
                    localStorage.setItem(STORAGE_KEY, JSON.stringify(payload));
                    try {
                        const info = await actionCreators.fetchInfoUnwrapped(payload.token);
                        if (info) {
                            dispatch({
                                type: 'betslip/setBookStake',
                                payload: {
                                    minWager: info.minWager,
                                    maxWager: info.maxWager,
                                    flags: info.flags,
                                    currencySymbol: info.currencySymbol,
                                    languageId: info.languageId
                                }
                            });

                            if (info.languageId === 1)
                                changeLanguage('es');
                            else
                                changeLanguage('en');
                        }
                    } catch (infoError) {
                        console.log(infoError);
                    }
                }
            } else {
                dispatch({ type: AuthActionType.LOGIN_FAIL });
            }

        } catch (e) {
            dispatch({ type: AuthActionType.CONNECTION_FAIL });
        }
    },
    fetchInfoUnwrapped: async (token) => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            }
        };

        const response = await fetch(`api/v1/players/0`, requestOptions);
        if (response.ok) {
            return await response.json();
        }

        return null;
    },
    loginUnwrapped: async (credentials) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(credentials)
        };

        const response = await fetch('authenticate/login', requestOptions);
        if (response.ok) {
            const authUser = await response.json();
            if (authUser?.accessToken)
                return authUser;
        }

        return false;
    }
};
