import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import AccountTab2 from '../components/AccountTab2';
import { injectIntl } from 'react-intl';

import SettledBets2 from './SettledBets2';
import UnsettledBets2 from './UnsettledBets2';
class Account2 extends Component {
    static displayName = Account2.name;

    constructor(props) {
        super(props);
    }

    render() {
        const item = this.props.intl.formatMessage({ id: 'item' });
        const currentBalance = this.props.intl.formatMessage({ id: 'currentBalance' });
        const availableBalance = this.props.intl.formatMessage({ id: 'availableBalance' });
        const amountAtRisk = this.props.intl.formatMessage({ id: 'amountAtRisk' });
        const lastWinLossFigure = this.props.intl.formatMessage({ id: 'lastWinLossFigure' });
        const thisWinLossFigure = this.props.intl.formatMessage({ id: 'thisWinLossFigure' });
        const bonusPoints = this.props.intl.formatMessage({ id: 'bonusPoints' });
        const amount = this.props.intl.formatMessage({ id: 'amount' });
        const actualFigures = this.props.intl.formatMessage({ id: 'actualFigures' });
        return (
            <Fragment>
                
                <div className="card my-4">
                    <div className="card-header card-header-content-between">
                        <h4 className="card-header-title">{this.props.userName}</h4>
                    </div>
                    <div className="card-body">
                        <div className="container2">
                            <h2>{actualFigures}</h2>
                            <ul className="responsive-table">
                                <li className="table-header">
                                    <div>{item}</div>
                                    <div>{amount}</div>
                                </li>
                                <li className="table-row">
                                    <div data-label={currentBalance}>{currentBalance}</div>
                                    <div data-label={this.props.balance.current}>{this.props.balance.current}</div>
                                </li>
                                <li className="table-row">
                                    <div data-label={availableBalance}>{availableBalance}</div>
                                    <div data-label={this.props.balance.real.toLocaleString()}>{this.props.balance.real.toLocaleString()}</div>
                                </li>
                                <li className="table-row">
                                    <div data-label={amountAtRisk}>{amountAtRisk}</div>
                                    <div data-label={this.props.balance.atRisk}>{this.props.balance.atRisk}</div>
                                </li>
                                <li className="table-row">
                                    <div data-label={lastWinLossFigure}>{lastWinLossFigure}</div>
                                    <div data-label={this.props.balance.lastWeek}>{this.props.balance.lastWeek}</div>
                                </li>
                                <li className="table-row">
                                    <div data-label={thisWinLossFigure}>{thisWinLossFigure}</div>
                                    <div data-label={this.props.balance.thisWeek}>{this.props.balance.thisWeek}</div>
                                </li>
                                <li className="table-row">
                                    <div data-label={bonusPoints}>{bonusPoints}</div>
                                    <div data-label={this.props.balance.bonus}>{this.props.balance.bonus}</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    </div>
                    
            </Fragment>
            
        );
    }
}

function mapStateToProps(state) {
    return {
        token: state.auth.token,
        userName: state.auth.userName,
        balance: state.balance
    };
}

export default connect(mapStateToProps, undefined)(injectIntl(Account2));