import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import AccountTab from '../components/AccountTab';
import { injectIntl } from 'react-intl';
class Account extends Component {
    static displayName = Account.name;

    constructor(props) {
        super(props);
    }

    render() {
        const item = this.props.intl.formatMessage({ id: 'item' });
        const currentBalance = this.props.intl.formatMessage({ id: 'currentBalance' });
        const availableBalance = this.props.intl.formatMessage({ id: 'availableBalance' });
        const amountAtRisk = this.props.intl.formatMessage({ id: 'amountAtRisk' });
        const lastWinLossFigure = this.props.intl.formatMessage({ id: 'lastWinLossFigure' });
        const thisWinLossFigure = this.props.intl.formatMessage({ id: 'thisWinLossFigure' });
        const bonusPoints = this.props.intl.formatMessage({ id: 'bonusPoints' });
        const amount = this.props.intl.formatMessage({ id: 'amount' });

        return (
            <Fragment>
                <AccountTab />
                <div className="card my-4">
                    <div className="card-header card-header-content-between">
                        <h4 className="card-header-title">{this.props.userName}</h4>
                    </div>
                    <div className="card-body">
                    <div className = "table-responsive">
                        <table className="container">
                            <thead>
                            <tr>
                                <th scope="col">{item}</th>
                                <th scope="col">{amount}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>{currentBalance}</td>
                                <td>{this.props.balance.current}</td>
                            </tr>
                            <tr>
                                <td>{availableBalance}</td>
                                <td>{this.props.balance.real.toLocaleString()}</td>
                            </tr>
                            <tr>
                                <td>{amountAtRisk}</td>
                                <td>{this.props.balance.atRisk}</td>
                            </tr>
                            <tr>
                                <td>{lastWinLossFigure}</td>
                                <td>{this.props.balance.lastWeek}</td>
                            </tr>
                            <tr>
                                <td>{thisWinLossFigure}</td>
                                <td>{this.props.balance.thisWeek}</td>
                            </tr>
                            <tr>
                                <td>{bonusPoints}</td>
                                <td>{this.props.balance.bonus}</td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        token: state.auth.token,
        userName: state.auth.userName,
        balance: state.balance
    };
}

export default connect(mapStateToProps, undefined)(injectIntl(Account));