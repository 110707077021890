// eslint-disable-next-line
export const BetStatusEnum = {
    FAIL: 'bet/fail',
    FATAL: 'bet/fatal',
    NONE: 'bet/none',
    PROCESS: 'bet/process',
    SUCCESS: 'bet/success'
};

export const BetslipActionType = {
    SET_TYPE: 'betslip/setType',
    STAKE_TYPE: 'betslip/setOpts',
    SEAT_TYPE: 'betslip/seatOpts',
    ADD: 'betslip/add',
    REMOVE: 'betslip/remove',
    MOVE_UP: 'betslip/moveup',
    PICK_OPTS: 'betslip/pickOpts',
    CLEAR: 'betslip/clear',
    ACCEPT_CHANGES: 'betslip/acceptChanges',
    PROCESSING: 'betslip/processing',
    BET_ACCEPTED: 'betslip/betAccepted',
    BET_FAILED: 'betslip/betFailed',
    RESET_STATUS: 'betslip/resetStatus',
    SET_ITEM_STAKE: 'betslip/setItemStake',
    SET_STAKE: 'betslip/setStake',
    BOOK_STAKE: 'betslip/setBookStake',
    PICK_MSG: 'betslip/pickMsg',
    TEASER_STYLE: 'betslip/teaserStyle',
    SELECTED_LEAGUES: 'betslip/leagueIds',
    GAMES_LOADED: 'betslip/gamesLoaded',
    READBACK_OPEN_STATE: 'betslip/setReadbackOpen',
    SET_OPEN_STATE: 'betslip/setOpenState',
    SET_MENU_STATE: 'betslip/setMenuState',
    BET_TEASERRULES: 'betslip/betTeaserRules',
    TOGGLE_FREEPLAY: 'betslip/toggleFreeplay',
    SET_OPENBET: 'betslip/openBet'
};