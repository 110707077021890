import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import rootReducer from './rootReducer';
import { ActionCreators } from './config/actions';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(initialState = {}) {

    const store = createStore(
        rootReducer,
        composeEnhancer(
            applyMiddleware(thunk)
        )
    )

    return store
}

export const Bootstrap = (store) => loadConfig(store).then((notrans) => {
    document.getElementsByTagName('html')[0].setAttribute('data-bs-theme', notrans.theme);
    const domain = window.location.hostname;
    const currentConfig = notrans.configurations.find(config => config.url === domain);
    store.dispatch(ActionCreators.setReadyStatus({ ...notrans, idgmtyp: currentConfig ? ((currentConfig.idgmtyp) || 0):0 }));
});

function loadConfig(store) {
    return new Promise(function (resolve) {
        let configUrl = document.getElementsByTagName('base')[0].getAttribute('href') + "config.json?v=" + (new Date().getTime());
        fetch(configUrl).then(response => response.json()).then(function (json) {
            resolve(json);
        }).catch(function (ex) {
            console.warn("external config not loaded", ex, ", proceeding with default one");
            resolve(ex);
        });
    });
}