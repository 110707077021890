import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { updateThemeColor, getCurrentThemeColor } from "../themeUtils";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { injectIntl } from 'react-intl';
class Colors extends Component {
    static displayName = Colors.name;

    constructor(props) {
        super(props);
        this.state = {
            isDialogOpen: true
        };
    }
    


    handleButton1 = () => {
        updateThemeColor('light');
    }

    handleButton2 = () => {
        updateThemeColor('yellow');
    }

    handleButton3 = () => {
        updateThemeColor('red');
    }

    handleCloseDialog = () => {
        this.setState({ isDialogOpen: false });
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    

    render() {
        const currentColor = getCurrentThemeColor();
        const isLightDisabled = currentColor === 'light';
        const isYellowDisabled = currentColor === 'yellow';
        const isRedDisabled = currentColor === 'red';
        const selectThemeColor = this.props.intl.formatMessage({ id: 'selectThemeColor' });
        const light = this.props.intl.formatMessage({ id: 'light' });
        const yellow = this.props.intl.formatMessage({ id: 'yellow' });
        const red = this.props.intl.formatMessage({ id: 'red' });
        return (
            <Fragment>
                <Modal
                    isOpen={this.state.isDialogOpen} backdrop="static">
                    <ModalHeader>{selectThemeColor}</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-4">
                                <div className="d-flex flex-column">
                                    <button className="btn btn-primary mb-2" onClick={() => { this.handleButton1(); this.handleCloseDialog(); }} disabled={isLightDisabled}>
                                        {light}
                                    </button>

                                    <button className="btn btn-primary mb-2" onClick={() => { this.handleButton2(); this.handleCloseDialog(); }} disabled={isYellowDisabled}>
                                        {yellow}
                                    </button>

                                    <button className="btn btn-primary" onClick={() => { this.handleButton3(); this.handleCloseDialog(); }} disabled={isRedDisabled}>
                                        {red}
                                    </button>
                                </div>
                            </div>
                            <div className="col-8">
                                <div className="d-flex flex-column">
                                    <img src="/Light.png" alt="Imagen 1" className="mb-2" />
                                    <img src="/Yellow.png" alt="Imagen 2" className="mb-2" />
                                    <img src="/Red.png" alt="Imagen 3" className="mb-2" />
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-secondary" onClick={this.handleCloseDialog}>Close</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        token: state.auth.token,
        userName: state.auth.userName,
        balance: state.balance
    };
}

export default connect(mapStateToProps, undefined)(injectIntl(Colors));
