import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Accordion, AccordionItem, AccordionHeader, AccordionBody } from 'reactstrap';
import { actionCreators as AuthActions } from '../store/auth/actions';
import { actionCreators as BetslipActions } from '../store/betslip/actions';
import { RawHtml } from '../components/RawHtml';
import { injectIntl } from 'react-intl';
import Betslip from '../components/Betslip';
import { BetStatusEnum } from '../store/betslip/types';
import { Fragment } from 'react';
import { ReactComponent as BaseballIcon } from 'openmoji/color/svg/26BE.svg';
import { ReactComponent as FootballIcon } from 'openmoji/color/svg/1F3C8.svg';
import { ReactComponent as GolfIcon } from 'openmoji/color/svg/1F3CC.svg';
import { ReactComponent as TennisIcon } from 'openmoji/color/svg/1F3BE.svg';
import { ReactComponent as CombatIcon } from 'openmoji/color/svg/1F94A.svg';
import { ReactComponent as SoccerIcon } from 'openmoji/color/svg/26BD.svg';
import { ReactComponent as BasketballIcon } from 'openmoji/color/svg/1F3C0.svg';
import { ReactComponent as HockeyIcon } from 'openmoji/color/svg/1F3D2.svg';
import { ReactComponent as BalanceScaleIcon } from 'openmoji/color/svg/2696.svg';
import { ReactComponent as MotorSportsIcon } from 'openmoji/color/svg/1F3CE.svg';
import { ReactComponent as OtherSportsIcon } from 'openmoji/color/svg/1F3AE.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import {
    generateSelectionId, createBetslipEventObject,
    PLAY_TYPE_VISITOR_SPREAD,
    PLAY_TYPE_HOME_SPREAD,
    PLAY_TYPE_TOTAL_OVER,
    PLAY_TYPE_TOTAL_UNDER,
    PLAY_TYPE_VISITOR_ODDS,
    PLAY_TYPE_HOME_ODDS,
    PLAY_TYPE_DRAW_ODDS
} from '../helpers/betslip';
import OddConverter, { formatHandicap, formatTotal } from '../helpers/oddConverter';

const formatTimePart = (input) => {
    const hourValue = input.getHours();
    const minuteValue = input.getMinutes();
    let hour = '' + hourValue;
    let minute = '' + minuteValue;
    const sufix = hourValue > 11 ? 'p' : 'a';

    if (hourValue > 12)
        hour = '' + (hourValue - 12);

    if (minuteValue < 10) {
        minute = `0${minuteValue}`;
    }

    return `${hour}:${minute}${sufix}`;
};

const AwayMoneyLine = ({ item, sport, competition, toggleItem, cartItemMap, translator }) => {
    if (item.am !== 0) {
        const play = PLAY_TYPE_VISITOR_ODDS;
        const eventId = generateSelectionId(item, play);
        const classList = ['owrapper'];
        if (cartItemMap[eventId])
            classList.push('active');
        if (item.amStyle)
            classList.push(item.amStyle);
        return (
            <div className={classList.join(" ")} onClick={() => toggleItem(createBetslipEventObject(item, play, competition, sport, translator))}>
                <span className="odds">{OddConverter(item.am)}</span>
                {item.amStyle === 'down' && <i className="material-icons">arrow_drop_down</i>}
                {item.amStyle === 'up' && <i className="material-icons">arrow_drop_up</i>}
            </div>
        );
    }

    return (
        <div className='owrapper disabled'>
            <i className="material-icons">lock</i>
        </div>
    );
}

const HomeMoneyLine = ({ item, sport, competition, toggleItem, cartItemMap, translator }) => {
    if (item.hm !== 0) {
        const play = PLAY_TYPE_HOME_ODDS;
        const eventId = generateSelectionId(item, play);
        const classList = ['owrapper'];
        if (cartItemMap[eventId])
            classList.push('active');
        if (item.hmStyle)
            classList.push(item.hmStyle);
        return (
            <div className={classList.join(" ")} onClick={() => toggleItem(createBetslipEventObject(item, play, competition, sport, translator))}>
                <span className="odds">{OddConverter(item.hm)}</span>
                {item.hmStyle === 'down' && <i className="material-icons">arrow_drop_down</i>}
                {item.hmStyle === 'up' && <i className="material-icons">arrow_drop_up</i>}
            </div>
        );
    }

    return (
        <div className='owrapper disabled'>
            <i className="material-icons">lock</i>
        </div>
    );
}

const DrawMoneyLine = ({ item, sport, competition, toggleItem, cartItemMap, translator }) => {
    if (item.dm !== 0) {
        const play = PLAY_TYPE_DRAW_ODDS;
        const eventId = generateSelectionId(item, play);
        const classList = ['owrapper'];
        if (cartItemMap[eventId])
            classList.push('active');
        if (item.dmStyle)
            classList.push(item.dmStyle);
        return (
            <div className={classList.join(" ")} onClick={() => toggleItem(createBetslipEventObject(item, play, competition, sport, translator))}>
                <span className="odds">{OddConverter(item.dm)}</span>
                {item.dmStyle === 'down' && <i className="material-icons">arrow_drop_down</i>}
                {item.dmStyle === 'up' && <i className="material-icons">arrow_drop_up</i>}
            </div>
        );
    }

    return (
        <div className='owrapper disabled'>
            <i className="material-icons">lock</i>
        </div>
    );
}

const AwaySpreadLine = ({ item, sport, competition, toggleItem, cartItemMap, translator }) => {
    if (item.apm !== 0) {
        const play = PLAY_TYPE_VISITOR_SPREAD;
        const eventId = generateSelectionId(item, play);
        const classList = ['owrapper'];
        if (cartItemMap[eventId])
            classList.push('active');
        if (item.asStyle)
            classList.push(item.asStyle);
        return (
            <div className={classList.join(" ")} onClick={() => toggleItem(createBetslipEventObject(item, play, competition, sport, translator))}>
                <span className="outcome">{formatHandicap(item.ap)}</span>
                <span className="odds">{OddConverter(item.apm)}</span>
                {item.asStyle === 'down' && <i className="material-icons">arrow_drop_down</i>}
                {item.asStyle === 'up' && <i className="material-icons">arrow_drop_up</i>}
            </div>
        );
    }

    return (
        <div className='owrapper disabled'>
            <i className="material-icons">lock</i>
        </div>
    );
}

const HomeSpreadLine = ({ item, sport, competition, toggleItem, cartItemMap, translator }) => {
    if (item.hpm !== 0) {
        const play = PLAY_TYPE_HOME_SPREAD;
        const eventId = generateSelectionId(item, play);
        const classList = ['owrapper'];
        if (cartItemMap[eventId])
            classList.push('active');
        if (item.hsStyle)
            classList.push(item.hsStyle);
        return (
            <div className={classList.join(" ")} onClick={() => toggleItem(createBetslipEventObject(item, play, competition, sport, translator))}>
                <span className="outcome">{formatHandicap(item.hp)}</span>
                <span className="odds">{OddConverter(item.hpm)}</span>
                {item.hsStyle === 'down' && <i className="material-icons">arrow_drop_down</i>}
                {item.hsStyle === 'up' && <i className="material-icons">arrow_drop_up</i>}
            </div>
        );
    }

    return (
        <div className='owrapper disabled'>
            <i className="material-icons">lock</i>
        </div>
    );
}

const OverLine = ({ item, sport, competition, toggleItem, cartItemMap, translator }) => {

    if (item.om !== 0) {
        const play = PLAY_TYPE_TOTAL_OVER;
        const eventId = generateSelectionId(item, play);
        const classList = ['owrapper'];
        if (cartItemMap[eventId])
            classList.push('active');
        if (item.omStyle)
            classList.push(item.omStyle);
        return (
            <div className={classList.join(" ")} onClick={() => toggleItem(createBetslipEventObject(item, play, competition, sport, translator))}>
                <span className="outcome">{formatTotal(item.ou, 'o')}</span>
                <span className="odds">{OddConverter(item.om)}</span>
                {item.omStyle === 'down' && <i className="material-icons">arrow_drop_down</i>}
                {item.omStyle === 'up' && <i className="material-icons">arrow_drop_up</i>}
            </div>
        );
    }

    return (
        <div className='owrapper disabled'>
            <i className="material-icons">lock</i>
        </div>
    );
}

const UnderLine = ({ item, sport, competition, toggleItem, cartItemMap, translator }) => {
    if (item.um !== 0) {
        const play = PLAY_TYPE_TOTAL_UNDER;
        const eventId = generateSelectionId(item, play);
        const classList = ['owrapper'];
        if (cartItemMap[eventId])
            classList.push('active');
        if (item.umStyle)
            classList.push(item.umStyle);

        return (
            <div className={classList.join(" ")} onClick={() => toggleItem(createBetslipEventObject(item, play, competition, sport, translator))}>
                <span className="outcome">{formatTotal(item.ou, 'u')}</span>
                <span className="odds">{OddConverter(item.um)}</span>
                {item.umStyle === 'down' && <i className="material-icons">arrow_drop_down</i>}
                {item.umStyle === 'up' && <i className="material-icons">arrow_drop_up</i>}
            </div>
        );
    }

    return (
        <div className='owrapper disabled'>
            <i className="material-icons">lock</i>
        </div>
    );
}

const RegularCompetitionView = ({ time, item, sport, competition, toggleItem, cartItemMap, translator, moneyLine, total, runLine, spread, puckLine, togglePersonalizedSelection, otherMatches, goBackFromMoreMatches }) => (
    <div className="container-fluid">
        <ul>
            <li>
                <div className="p-container2">
                    <div className="c1">
                        <time>{formatTimePart(time)}</time>
                    </div>
                    <div className="c2">
                        <div className="truncate">
                            <div className="p1">
                                <div className="team">{item.vtm}</div>

                            </div>
                            <div className="p1">
                                <div className="team">VS</div>
                            </div>
                            <div className="p2">
                                <div className="team">{item.htm}</div>

                            </div>
                        </div>
                    </div>
                </div>
                {item.cc > 0 && !otherMatches && (
                    <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={(event) => togglePersonalizedSelection(event, { gameIds: [item.id] })}
                    >
                        +
                    </button>
                )}
                {otherMatches && (
                    <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={(event) => goBackFromMoreMatches({ gameIds: item.id })}
                    >
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </button>
                )}
            </li>
        </ul>

        <div className="row">
            <div className="col-2">
                {getHeadlineBySport(sport, moneyLine, total, runLine, spread, puckLine)}
            </div>
            <div className="col-10">
                <ul>
                    <ul className="list-unstyled">
                        <li>
                            <div className="p-container">

                                <div className="c2">
                                    <div className="truncate">
                                        <div className="p1">
                                            <div className="team">{item.vtm}</div>

                                        </div>
                                        <div className="p2">
                                            <div className="team">{item.htm}</div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <li>
                        <div className="odd">
                            <AwaySpreadLine competition={competition} sport={sport} item={item} toggleItem={toggleItem} cartItemMap={cartItemMap} translator={translator} />
                        </div>
                        <div className="odd">
                            <HomeSpreadLine competition={competition} sport={sport} item={item} toggleItem={toggleItem} cartItemMap={cartItemMap} translator={translator} />
                        </div>
                    </li>
                    <li>
                        <div className="odd">
                            <OverLine competition={competition} sport={sport} item={item} toggleItem={toggleItem} cartItemMap={cartItemMap} translator={translator} />
                        </div>
                        <div className="odd">
                            <UnderLine competition={competition} sport={sport} item={item} toggleItem={toggleItem} cartItemMap={cartItemMap} translator={translator} />
                        </div>
                    </li>
                    <li>
                        <div className="odd">
                            <AwayMoneyLine competition={competition} sport={sport} item={item} toggleItem={toggleItem} cartItemMap={cartItemMap} translator={translator} />
                        </div>
                        <div className="odd">
                            <HomeMoneyLine competition={competition} sport={sport} item={item} toggleItem={toggleItem} cartItemMap={cartItemMap} translator={translator} />
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
);

const TntPropCompetitionView = ({ time, item, sport, competition, toggleItem, cartItemMap, translator, moneyLine, total, runLine, spread, puckLine, togglePersonalizedSelection, otherMatches, goBackFromMoreMatches }) => (
    <div className="container-fluid">
        <ul>
            <li>
                <div className="p-container2">
                    <div className="c1">
                        <time>{formatTimePart(time)}</time>
                    </div>
                    <div className="c2">
                        <div className="truncate">
                            <div className="p1">
                                <div className="team">{item.vtm}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>

        <div className="row">
            <div className="col-12">
                <ul>
                    <li>
                        <div className="odd">
                            <AwayMoneyLine competition={competition} sport={sport} item={item} toggleItem={toggleItem} cartItemMap={cartItemMap} translator={translator} />
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
);

const MLBCompetitionView = ({ time, item, sport, competition, toggleItem, cartItemMap, translator, moneyLine, total, runLine, spread, puckLine, togglePersonalizedSelection, otherMatches, goBackFromMoreMatches }) => (
    <div className="container-fluid">
        <ul>
            <li>
                <div className="p-container2">
                    <div className="c1">
                        <time>{formatTimePart(time)}</time>
                    </div>
                    <div className="c2">
                        <div className="truncate">
                            <div className="p1">
                                <div className="team">{item.vtm}</div>

                            </div>
                            <div className="p1">
                                <div className="team">VS</div>
                            </div>
                            <div className="p2">
                                <div className="team">{item.htm}</div>

                            </div>
                        </div>
                    </div>
                </div>
                {item.cc > 0 && !otherMatches && (
                    <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={(event) => togglePersonalizedSelection(event, { gameIds: [item.id] })}
                    >
                        +
                    </button>
                )}
                {otherMatches && (
                    <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={(event) => goBackFromMoreMatches({ gameIds: item.id })}
                    >
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </button>
                )}
            </li>
        </ul>

        <div className="row">
            <div className="col-2">
                {getHeadlineBySport(sport, moneyLine, total, runLine, spread, puckLine)}
            </div>
            <div className="col-10">
                <ul className="list-unstyled">
                    <ul className="list-unstyled">
                        <li>
                            <div className="p-container">
                                <div className="c2">
                                    <div className="truncate">
                                        <div className="p1">
                                            <div className="team">{item.vtm}</div>

                                        </div>
                                        <div className="p2">
                                            <div className="team">{item.htm}</div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <li>
                        <div className="odd">
                            <AwayMoneyLine competition={competition} sport={sport} item={item} toggleItem={toggleItem} cartItemMap={cartItemMap} translator={translator} />
                        </div>
                        <div className="odd">
                            <HomeMoneyLine competition={competition} sport={sport} item={item} toggleItem={toggleItem} cartItemMap={cartItemMap} translator={translator} />
                        </div>
                    </li>
                    <li>
                        <div className="odd">
                            <OverLine competition={competition} sport={sport} item={item} toggleItem={toggleItem} cartItemMap={cartItemMap} translator={translator} />
                        </div>
                        <div className="odd">
                            <UnderLine competition={competition} sport={sport} item={item} toggleItem={toggleItem} cartItemMap={cartItemMap} translator={translator} />
                        </div>
                    </li>
                    <li>
                        <div className="odd">
                            <AwaySpreadLine competition={competition} sport={sport} item={item} toggleItem={toggleItem} cartItemMap={cartItemMap} translator={translator} />
                        </div>
                        <div className="odd">
                            <HomeSpreadLine competition={competition} sport={sport} item={item} toggleItem={toggleItem} cartItemMap={cartItemMap} translator={translator} />
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
);

const SoccerCompetitionView = ({ time, item, sport, competition, toggleItem, cartItemMap, translator, moneyLine, total, runLine, spread, puckLine, togglePersonalizedSelection, otherMatches, goBackFromMoreMatches }) => (
    <div className="container-fluid">
        <ul className="list-unstyled">
            <li>
                <div className="p-container2">
                    <div className="c1">
                        <time>{formatTimePart(time)}</time>
                    </div>
                    <div className="c2">
                        <div className="truncate">
                            <div className="p1">
                                <div className="team">{item.vtm}</div>
                            </div>
                            <div className="p1">
                                <div className="team">VS</div>
                            </div>
                            <div className="p2">
                                <div className="team">{item.htm}</div>
                            </div>
                        </div>
                    </div>
                </div>
                {item.cc > 0 && !otherMatches && (
                    <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={(event) => togglePersonalizedSelection(event, { gameIds: [item.id] })}
                    >
                        +
                    </button>
                )}
                {otherMatches && (
                    <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={(event) => goBackFromMoreMatches({ gameIds: item.id })}
                    >
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </button>
                )}
            </li>
        </ul>

        <div className="row">
            <div className="col-2">
                {getHeadlineBySport(sport, moneyLine, total, runLine, spread, puckLine)}
            </div>
            <div className="col-10">
                <ul className="list-unstyled">
                    <li>
                        <div className="p-container">
                            <div className="c2">
                                <div className="truncate">
                                    <div className="p1">
                                        <div className="team">{item.vtm}</div>

                                    </div>
                                    <div className="p3">
                                        <div className="team">Draw</div>

                                    </div>
                                    <div className="p2">
                                        <div className="team">{item.htm}</div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <ul className="list-unstyled">
                    <li className="d-flex">
                        <div className="odd">
                            <AwayMoneyLine competition={competition} sport={sport} item={item} toggleItem={toggleItem} cartItemMap={cartItemMap} translator={translator} />
                        </div>
                        <div className="odd">
                            <HomeMoneyLine competition={competition} sport={sport} item={item} toggleItem={toggleItem} cartItemMap={cartItemMap} translator={translator} />
                        </div>
                        <div className="odd">
                            <DrawMoneyLine competition={competition} sport={sport} item={item} toggleItem={toggleItem} cartItemMap={cartItemMap} translator={translator} />
                        </div>
                    </li>
                    <li className="d-flex">
                        <div className="odd">
                            <OverLine competition={competition} sport={sport} item={item} toggleItem={toggleItem} cartItemMap={cartItemMap} translator={translator} />
                        </div>
                        <div className="odd">
                            <UnderLine competition={competition} sport={sport} item={item} toggleItem={toggleItem} cartItemMap={cartItemMap} translator={translator} />
                        </div>
                    </li>
                    <li className="d-flex">
                        <div className="odd">
                            <AwaySpreadLine competition={competition} sport={sport} item={item} toggleItem={toggleItem} cartItemMap={cartItemMap} translator={translator} />
                        </div>
                        <div className="odd">
                            <HomeSpreadLine competition={competition} sport={sport} item={item} toggleItem={toggleItem} cartItemMap={cartItemMap} translator={translator} />
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
);

const CompetitionGroupView = ({ name, games, sport, toggleItem, cartItemMap, translator, moneyLine, total, runLine, spread, puckLine, togglePersonalizedSelection, otherMatches, goBackFromMoreMatches }) => {
    if (!games.length)
        return null;

    return (
        <div className="group">

            <div className="matches">

                {games.map((game, sequence) => {
                    const gameTime = new Date(game.kickoff * 1000);

                    if (sport === 'SOC')
                        return <SoccerCompetitionView
                            key={`G${game.id}`}
                            time={gameTime}
                            item={game}
                            competition={name}
                            toggleItem={toggleItem}
                            cartItemMap={cartItemMap}
                            sport={sport}
                            translator={translator}
                            moneyLine={moneyLine}
                            total={total}
                            runLine={runLine}
                            spread={spread}
                            puckLine={puckLine}
                            togglePersonalizedSelection={togglePersonalizedSelection}
                            otherMatches={otherMatches}
                            goBackFromMoreMatches={goBackFromMoreMatches}
                        />;

                    if (sport === 'MLB')
                        return <MLBCompetitionView
                            key={`G${game.id}`}
                            time={gameTime}
                            item={game}
                            competition={name}
                            toggleItem={toggleItem}
                            cartItemMap={cartItemMap}
                            sport={sport}
                            translator={translator}
                            moneyLine={moneyLine}
                            total={total}
                            runLine={runLine}
                            spread={spread}
                            puckLine={puckLine}
                            togglePersonalizedSelection={togglePersonalizedSelection}
                            otherMatches={otherMatches}
                            goBackFromMoreMatches={goBackFromMoreMatches}
                        />;
                    if (sport === 'TNT' || sport === 'PROP')
                        return <TntPropCompetitionView
                            key={`G${game.id}`}
                            time={gameTime}
                            item={game}
                            competition={name}
                            toggleItem={toggleItem}
                            cartItemMap={cartItemMap}
                            sport={sport}
                            translator={translator}
                            moneyLine={moneyLine}
                            total={total}
                            runLine={runLine}
                            spread={spread}
                            puckLine={puckLine}
                            togglePersonalizedSelection={togglePersonalizedSelection}
                            otherMatches={otherMatches}
                            goBackFromMoreMatches={goBackFromMoreMatches}
                        />;

                    return <RegularCompetitionView
                        key={`G${game.id}`}
                        time={gameTime}
                        item={game}
                        competition={name}
                        toggleItem={toggleItem}
                        cartItemMap={cartItemMap}
                        sport={sport}
                        translator={translator}
                        moneyLine={moneyLine}
                        total={total}
                        runLine={runLine}
                        spread={spread}
                        puckLine={puckLine}
                        togglePersonalizedSelection={togglePersonalizedSelection}
                        otherMatches={otherMatches}
                        goBackFromMoreMatches={goBackFromMoreMatches}
                    />;
                })}
            </div>
        </div>
    );
};

const getHeadlineBySport = (sport, moneyLine, total, runLine, spread, puckLine) => {
    if (sport === 'SOC')
        return (
            <div className="info">
                <div className="teams">
                </div>
                <div className="market">
                    <span className="capsule">{spread}</span>
                </div>
                <div className="market">
                    <span className="capsule">{total}</span>
                </div>
                <div className="market">
                    <span className="capsule">{moneyLine}</span>
                </div>
            </div>
        );

    if (sport === 'MLB')
        return (
            <div className="info">
                <div className="teams">
                </div>
                <div className="market">
                    <span className="capsule">{runLine}</span>
                </div>
                <div className="market">
                    <span className="capsule">{total}</span>
                </div>
                <div className="market">
                    <span className="capsule">{moneyLine}</span>
                </div>
            </div>
        );

    if (sport === 'NHL')
        return (
            <div className="info">
                <div className="teams">
                </div>
                <div className="market">
                    <span className="capsule">{puckLine}</span>
                </div>
                <div className="market">
                    <span className="capsule">{total}</span>
                </div>
                <div className="market">
                    <span className="capsule">{moneyLine}</span>
                </div>
            </div>
        );

    if (sport === 'TNT' || sport === 'PROP')
        return (
            <div className="info">
                <div className="teams">
                </div>
                <div className="market">
                    <span className="capsule">{moneyLine}</span>
                </div>
            </div>
        );

    return (
        <div className="info">
            <div className="teams">
            </div>
            <div className="market">
                <span className="capsule">{moneyLine}</span>
            </div>
            <div className="market">
                <span className="capsule">{total}</span>
            </div>
            <div className="market">
                <span className="capsule">{spread}</span>
            </div>
        </div>
    );
};

const CompetitionView = ({ name, groups, sport, toggleItem, cartItemMap, moneyLine, total, runLine, spread, puckLine, ticks, translator, togglePersonalizedSelection, otherMatches, goBackFromMoreMatches }) => {
    const groupName = groups.length > 0 ? groups[0].name : '';
    return (
        <div className="competition2">
            <div className="header d-flex justify-content-center">
                <div className="col-auto">
                    <RawHtml>{groupName}</RawHtml>
                </div>
                <div className="col-auto">
                </div>
            </div>

            <div className="groups">
                {groups.map((group, sequence) => (
                    <CompetitionGroupView key={`G${sequence}_${group.id}`}
                        translator={translator}
                        name={group.name}
                        sport={sport}
                        games={group.games}
                        toggleItem={toggleItem}
                        cartItemMap={cartItemMap}
                        moneyLine={moneyLine}
                        total={total}
                        runLine={runLine}
                        spread={spread}
                        puckLine={puckLine}
                        togglePersonalizedSelection={togglePersonalizedSelection}
                        otherMatches={otherMatches}
                        goBackFromMoreMatches={goBackFromMoreMatches}

                    />
                ))}
            </div>
        </div>
    );
};

const filterDataset = (games, visibleItems) => {
    const resultSet = [];
    if (!visibleItems || !visibleItems.length)
        return games;

    for (var i = 0; i < visibleItems.length; i++) {
        const selection = visibleItems[0];
        const selectionTree = {
            ...selection.league,
            groups: [
                {
                    ...selection.group,
                    games: [selection]
                }
            ]
        };
        resultSet.push(selectionTree);
    }

    return resultSet;
};

class PreMatch extends Component {
    static displayName = PreMatch.name;
    constructor(props) {
        super(props);
        this.state = {
            matches: window.matchMedia("(min-width: 768px)").matches,
            categories: [],
            shortDescCategories: [],
            leagues: [],
            filteredItems: [],
            selectedItems: [],
            visibleItems: [],
            shortDesc: [],
            categoryFilterOpen: true,
            loading: true,
            otherMatches: false,
            fatherId: 0,
            accordionId: 'L0',
            ticks: 0,
            openItems: [],
            result: null,
            history: [],
        };
        this.didMount = false;
        this.toggleAccordion = this.toggleAccordion.bind(this);
        this.toggleCategoryFilter = this.toggleCategoryFilter.bind(this);
        this.toggleLeagueSelection = this.toggleLeagueSelection.bind(this);
        this.goBackFromMoreMatches = this.goBackFromMoreMatches.bind(this);
        this.togglePersonalizedSelection = this.togglePersonalizedSelection.bind(this);
        this.filterItems = this.filterItems.bind(this);
        this.quickLeagueSelection = this.quickLeagueSelection.bind(this);
        this.handleToggleItem = this.handleToggleItem.bind(this);
        this.handleLoadLines = this.handleLoadLines.bind(this);
        this.toggleFillOpen = this.toggleFillOpen.bind(this);
        this.onSendBets = this.onSendBets.bind(this);
    }

    toggleItems = (id) => {
        const { openItems } = this.state;
        const isOpen = openItems.includes(id);

        this.setState({
            openItems: isOpen ? openItems.filter(item => item !== id) : [...openItems, id]
        })
    }

    setMatchMedia = (e) => {
        if (this.didMount) {
            this.setState({ matches: e.matches });
        }
    }

    initialiseRefresh() {
        if (!this.interval) {
            this.interval = setInterval(() => {
                const ticks = this.state.ticks;
                if (ticks > 0) {
                    this.setState({ ticks: (ticks - 1) });
                } else {
                    const otherMatches = this.state.otherMatches;
                    if (!otherMatches) {
                        const selectedItems = this.state.selectedItems;
                        if (selectedItems.length) {
                            this.toggleLeagueSelection(null, { idLeague: selectedItems[0] }, true);
                        };
                    } else {
                        this.togglePersonalizedSelection(null, { gameIds: [this.state.fatherId.gameIds] }, true)
                    }
                }
            }, 1000);
        }
    }

    componentDidMount() {
        this.didMount = true;
        window.matchMedia("(min-width: 768px)").addEventListener('change', this.setMatchMedia);
        this.populateCategoryData();
    }

    componentWillUnmount = () => {
        this.didMount = false;
        window.matchMedia("(min-width: 768px)").removeEventListener('change', this.setMatchMedia);
        if (this.interval) {
            clearInterval(this.interval);
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.languageId !== prevProps.languageId) {
            this.populateCategoryData();
        }
    }

    handleLoadLines() {
        this.toggleCategoryFilter();
        this.props.fetchGames(this.state.selectedItems.join(","));
        this.props.fetchPersonalizedGames(this.state.selectedItems);
    }

    handleToggleItem(item) {
        this.props.toggleItem(item, this.props.token);
    }

    toggleCategoryFilter() {
        this.setState(prevState => ({
            categoryFilterOpen: !prevState.categoryFilterOpen
        }));
    }

    toggleAccordion(id) {
        this.setState(prevState => ({
            accordionId: (prevState.accordionId === id) ? null : id
        }));
    }
    goBackFromMoreMatches(gameIds) {
        const league = { idLeague: this.state.selectedItems[0] };
        this.toggleLeagueSelection(null, league, false);

    }
    toggleLeagueSelection(event, league, refresh) {
        this.setState({ otherMatches: false });
        this.setState(prevState => ({
            visibleItems: [],
            selectedItems: [league.idLeague],
            history: [],
            ticks: 35
        }));

        if (!this.state.matches && !refresh && !this.state.otherMatches)
            this.props.toggleSportMenu();

        setTimeout(() => this.props.fetchGames(this.state.selectedItems.join(",")), 300);
        this.initialiseRefresh();
    }

   

    togglePersonalizedSelection(event, gameIds, refresh) {
        this.setState(prevState => ({
            otherMatches: true,
            visibleItems: [],
            history: [],
            ticks: 35,
            fatherId: gameIds
        }));
        setTimeout(() => this.props.fetchPersonalizedGames(gameIds.gameIds[0]), 300);
        this.initialiseRefresh();
    }

    toggleFillOpen(event) {
        if (this.interval) {
            clearInterval(this.interval);
            this.interval = undefined;
        }

        this.populateFillOpenBets();
    }

    selectFillOpenSpot(event, wager) {
        this.props.toggleOpenWager(wager);
        this.toggleLeagueSelection(event, { id: -120 }, true);
    }

    onSendBets(event) {
        this.props.sendBets(event);
        this.setState({ ticks: 1 });
    }

    filterItems(event) {
        const value = event.target.value.toUpperCase();
        if (value) {
            const result = this.state.leagues.filter((data) => { return data.leagueDescription.search(value) !== -1; }).slice(0, 5).map(v => ({ ...v, ot: 1, label: this.props.intl.formatMessage({ id: 'league' }) }));
            const dataset = this.props.games;
            if (dataset) {
                for (var i = 0; i < dataset.length; i++) {
                    const league = dataset[i];
                    for (var j = 0; j < league.groups.length; j++) {
                        const group = league.groups[j];
                        for (var k = 0; k < group.games.length; k++) {
                            const fixture = group.games[k];
                            if (fixture.lookup.search(value) !== -1) {
                                result.push({ ...fixture, ot: 2, label: this.props.intl.formatMessage({ id: 'match' }), name: fixture.lookup, league, group });
                            }
                        }
                    }
                }
            }

            this.setState(prevState => ({
                filteredItems: result.slice(0, 5)
            }));
        } else {
            this.setState(prevState => ({
                filteredItems: []
            }));
        }
    }

    quickLeagueSelection(event, selection) {
        this.setState({
            selectedItems: [],
            visibleItems: [],
            filteredItems: []
        });
        if (!this.state.matches)
            this.props.toggleSportMenu();

        if (selection.ot === 1)
            this.props.fetchGames(selection.idLeague);

        if (selection.ot === 2) {
            this.setState({
                visibleItems: [selection]
            });
        }
    }


    getSportNavigation(featured, next15Minutes, nextHour, next2Hours, next6Hours, today, tomorrow, leagueTeamGm) {
        const { openItems } = this.state;

        const toggle = (id) => {
            this.setState(prevState => ({
                openItems: prevState.openItems.includes(id)
                    ? prevState.openItems.filter(item => item !== id)
                    : [...prevState.openItems, id]
            }));
        }

        const getIconByLeagueName = (leagueName) => {
            const lowerCaseLeagueName = leagueName.toLowerCase();

            if (lowerCaseLeagueName.includes('football')) {
                return <FootballIcon width="32" height="32" />;
            } else if (lowerCaseLeagueName.includes('basketball')) {
                return <BasketballIcon width="32" height="32" />;
            } else if (lowerCaseLeagueName.includes('baseball')) {
                return <BaseballIcon width="32" height="32" />;
            } else if (lowerCaseLeagueName.includes('hockey')) {
                return <HockeyIcon width="32" height="32" />;
            } else if (lowerCaseLeagueName.includes('motor')) {
                return <MotorSportsIcon width="32" height="32" />;
            } else if (lowerCaseLeagueName.includes('fighting')) {
                return <CombatIcon width="32" height="32" />;
            } else if (lowerCaseLeagueName.includes('tennis')) {
                return <TennisIcon width="32" height="32" />;
            } else if (lowerCaseLeagueName.includes('other')) {
                return <OtherSportsIcon width="32" height="32" />;
            } else if (lowerCaseLeagueName.includes('soccer')) {
                return <SoccerIcon width="32" height="32" />;
            } else if (lowerCaseLeagueName.includes('politics')) {
                return <BalanceScaleIcon width="32" height="32" />;
            } else if (lowerCaseLeagueName.includes('baloncesto')) {
                return <BasketballIcon width="32" height="32" />;
            } else if (lowerCaseLeagueName.includes('beisbol')) {
                return <BaseballIcon width="32" height="32" />;
            } else if (lowerCaseLeagueName.includes('hockey')) {
                return <HockeyIcon width="32" height="32" />;
            } else if (lowerCaseLeagueName.includes('motor')) {
                return <MotorSportsIcon width="32" height="32" />;
            } else if (lowerCaseLeagueName.includes('combates')) {
                return <CombatIcon width="32" height="32" />;
            } else if (lowerCaseLeagueName.includes('tenis')) {
                return <TennisIcon width="32" height="32" />;
            } else if (lowerCaseLeagueName.includes('otros')) {
                return <OtherSportsIcon width="32" height="32" />;
            } else if (lowerCaseLeagueName.includes('soccer')) {
                return <SoccerIcon width="32" height="32" />;
            } else if (lowerCaseLeagueName.includes('pol')) {
                return <BalanceScaleIcon width="32" height="32" />;
            } else if (lowerCaseLeagueName.includes('futbol')) {
                return <FootballIcon width="32" height="32" />;
            } else if (lowerCaseLeagueName.includes('golf')) {
                return <GolfIcon width="32" height="32" />;
            } else {
                return null;
            }
        };

        const { sportTree, leagues, categories } = this.state;

        if (!sportTree || !categories || !leagues) {
            return <div>Loading...</div>;
        }



        return (
            <aside className={'app-aside ' + (this.props.sportMenuOpen ? 'isShown' : '')}>
                <div className="offcanvas-header d-lg-none">
                    <h5 className="offcanvas-title">Sports</h5>
                    <button type="button"
                        className="btn-close"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        onClick={this.props.toggleSportMenu} />
                </div>
                <div className="offcanvas-body custom-scrollbar">
                    <div className="dropdown">
                        <a className="text-decoration-none px-1" onClick={(event) => this.toggleFillOpen(event)}>
                            <strong>&nbsp;&nbsp;{this.props.intl.formatMessage({ id: 'fillOpen' })}</strong>
                        </a>
                    </div>
                    <hr />
                    <Accordion
                        open={openItems}
                        toggle={toggle}
                        className="accordion2"
                    >
                        <AccordionItem key={0} className="accordion2-item">
                            <AccordionHeader targetId="time-filters" className="accordion2-header">
                                {this.props.intl.formatMessage({ id: 'showAllGames' })}
                            </AccordionHeader>
                            <AccordionBody accordionId="time-filters" className="accordion2-body">
                                <ul className="btn-toggle-nav list-unstyled">
                                    <li>
                                        <a className="text-decoration-none py-0"
                                            onClick={(event) => this.toggleLeagueSelection(event, { idLeague: -15 })}>
                                            {next15Minutes}
                                        </a>
                                    </li>
                                    <li>
                                        <a className="text-decoration-none py-0"
                                            onClick={(event) => this.toggleLeagueSelection(event, { idLeague: -60 })}>
                                            {nextHour}
                                        </a>
                                    </li>
                                    <li>
                                        <a className="text-decoration-none py-0"
                                            onClick={(event) => this.toggleLeagueSelection(event, { idLeague: -120 })}>
                                            {next2Hours}
                                        </a>
                                    </li>
                                    <li>
                                        <a className="text-decoration-none py-0"
                                            onClick={(event) => this.toggleLeagueSelection(event, { idLeague: -360 })}>
                                            {next6Hours}
                                        </a>
                                    </li>
                                    <li>
                                        <a className="text-decoration-none py-0"
                                            onClick={(event) => this.toggleLeagueSelection(event, { idLeague: -999 })}>
                                            {today}
                                        </a>
                                    </li>
                                    <li>
                                        <a className="text-decoration-none py-0"
                                            onClick={(event) => this.toggleLeagueSelection(event, { id: -1000 })}>
                                            {tomorrow}
                                        </a>
                                    </li>
                                </ul>
                            </AccordionBody>
                        </AccordionItem>
                        {Object.keys(sportTree).map((sport, sportIndex) => (
                            <AccordionItem key={sportIndex} className="accordion2-item">
                                <AccordionHeader targetId={`sport-${sportIndex}`} className="accordion2-header">
                                    <div>
                                        {getIconByLeagueName(sport)}
                                    </div>
                                    {sport}
                                </AccordionHeader>
                                <AccordionBody accordionId={`sport-${sportIndex}`} className="accordion2-body">
                                    <Accordion
                                        open={openItems}
                                        toggle={toggle}
                                    >
                                        {Object.keys(sportTree[sport].l2).map((league, leagueIndex) => (
                                            <AccordionItem key={leagueIndex} className="accordion-item">
                                                <AccordionHeader targetId={`id-${sportIndex}-${leagueIndex}`} className="accordion2-header">
                                                    {league}
                                                </AccordionHeader>
                                                <AccordionBody accordionId={`id-${sportIndex}-${leagueIndex}`} className="accordion2-body">
                                                    <ul className="btn-toggle-nav list-unstyled">
                                                        {sportTree[sport].l2[league].leagues.map((filteredEvent, sequence) => (
                                                            <li key={`L${sequence}_${filteredEvent.idLeague}`}>
                                                                <a className="text-decoration-none py-0"
                                                                    onClick={(event) => this.toggleLeagueSelection(event, filteredEvent)}>
                                                                    <RawHtml>{filteredEvent.leagueDescription}</RawHtml>
                                                                </a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </AccordionBody>
                                            </AccordionItem>
                                        ))}
                                    </Accordion>
                                </AccordionBody>
                            </AccordionItem>
                        ))}
                    </Accordion>
                </div>
            </aside>
        );
    }

    render() {
        const moneyLine = this.props.intl.formatMessage({ id: 'moneyLine' });
        const total = this.props.intl.formatMessage({ id: 'total' });
        const runLine = this.props.intl.formatMessage({ id: 'runLine' });
        const spread = this.props.intl.formatMessage({ id: 'spread' });
        const puckLine = this.props.intl.formatMessage({ id: 'puckLine' });
        const featured = this.props.intl.formatMessage({ id: 'featured' });
        const next15Minutes = this.props.intl.formatMessage({ id: 'next15Minutes' });
        const nextHour = this.props.intl.formatMessage({ id: 'nextHour' });
        const next2Hours = this.props.intl.formatMessage({ id: 'next2Hours' });
        const next6Hours = this.props.intl.formatMessage({ id: 'next6Hours' });
        const today = this.props.intl.formatMessage({ id: 'today' });
        const tomorrow = this.props.intl.formatMessage({ id: 'tomorrow' });
        const leagueTeamGm = this.props.intl.formatMessage({ id: 'leagueTeamGm' });
        const navigation = this.getSportNavigation(featured, next15Minutes, nextHour, next2Hours, next6Hours, today, tomorrow, leagueTeamGm);
        return (
            <div className="app-wrapper2">
                <div className="row align-items-center">
                    <div className="col-10">
                        <div className="input-group my-1">
                            <input className="form-control pe-5 rounded" type="text" placeholder={leagueTeamGm}
                                onChange={(event) => this.filterItems(event)}
                                onFocus={(event) => this.filterItems(event)} />
                            <i className="material-icons position-absolute top-50 end-0 translate-middle-y zindex-5 me-3">search</i>
                        </div>
                    </div>
                    <div className="col-2">
                        <button type="button" className="btn btn-primary-secondSkin btn-sm w-100">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clock" viewBox="0 0 16 16">
                                <path d="M8 3.5a.5.5 0 0 1 .5.5v4l3 3a.5.5 0 0 1-.707.707l-3-3A.5.5 0 0 1 8 8V4a.5.5 0 0 1 .5-.5z" />
                                <path d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z" />
                            </svg>
                            &nbsp;{this.state.ticks}
                        </button>
                    </div>
                </div>


                <div>

                    <ul className="list-group">
                        {this.state.filteredItems.map((league, index) => {
                            return (
                                <li key={league.idLeague} className="list-group-item d-flex justify-content-between align-items-start" onClick={(event) => this.quickLeagueSelection(event, league)}>
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">{league.leagueDescription}</div>
                                        <RawHtml>{league.name}</RawHtml>
                                    </div>
                                </li>)
                        })}
                    </ul>
                </div>
                <div className="app-container">
                    {navigation}
                    <section className="app-content custom-scrollbar">
                        {this.props.status === BetStatusEnum.PROCESS &&
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        }

                        {this.state.history.length === 0 &&
                            <div className="matches">
                                {filterDataset(this.props.games ?? [], this.state.visibleItems).map((league) => {
                                    return <CompetitionView key={league.id}
                                        name={league.name}
                                        groups={league.groups}
                                        sport={league.sport}
                                        toggleItem={this.handleToggleItem}
                                        cartItemMap={this.props.cartItemMap}
                                        moneyLine={moneyLine}
                                        total={total}
                                        runLine={runLine}
                                        spread={spread}
                                        puckLine={puckLine}
                                        ticks={this.state.ticks}
                                        translator={this.props.intl}
                                        togglePersonalizedSelection={this.togglePersonalizedSelection}
                                        otherMatches={this.state.otherMatches}
                                        goBackFromMoreMatches={this.goBackFromMoreMatches}
                                    />;
                                })}
                            </div>
                        }
                        {this.state.history &&
                            <div>
                                {this.state.history.map((wager) => {
                                    return (
                                        <div key={wager.idWager} className="card my-2">
                                            <div className="card-body">
                                                <h4 className="card-title">{wager.desc}</h4>
                                                {
                                                    wager.items.filter(b => b.idGame).map((item, index) => (
                                                        <Fragment key={index}>
                                                            <p>#{(index + 1)}&nbsp;{item.idSport}&nbsp;{item.desc}&nbsp;-&nbsp;{item.gameDate}</p>
                                                        </Fragment>
                                                    ))
                                                }
                                                <div>
                                                    <div className="d-flex justify-content-between">
                                                        <p className="text-body fw-semibold">{this.props.intl.formatMessage({ id: 'openSpots' })}</p>
                                                        <p className="text-body fw-semibold">{wager.openItems}</p>
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <p className="text-body fw-semibold">{this.props.intl.formatMessage({ id: 'risk' })}</p>
                                                        <p className="text-body fw-semibold">{this.props.info.currencySymbol}{wager.risk.toLocaleString()}</p>
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <p className="text-body fw-semibold">{this.props.intl.formatMessage({ id: 'toWin' })}</p>
                                                        <p className="text-danger fw-semibold">{this.props.info.currencySymbol}{wager.toWin.toLocaleString()}</p>
                                                    </div>
                                                </div>
                                                <button className="btn btn-primary w-100" onClick={(event) => this.selectFillOpenSpot(event, wager)}>{this.props.intl.formatMessage({ id: 'fillOpen' })}</button>
                                            </div>
                                        </div>)
                                })
                                }
                            </div>
                        }


                    </section>
                    <Betslip isOpen={this.props.betslipOpen} toggleBetslip={this.props.toggleBetslip} sendBets={(event) => this.onSendBets(event)} />
                </div>
            </div>
        );
    }


    async populateCategoryData() {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `bearer ${this.props.token}`
            }
        };

        this.setState({ loading: true });
        const response = await fetch(`api/v1/sports/0/leagues`, requestOptions);
        if (response.ok) {
            const categoriesData = await response.json();
            const sportTree = {};

            for (var i = 0; i < categoriesData.length; i++) {
                const category = categoriesData[i];
                if (category.rowDescription) {
                    const sport = (sportTree[category.rowDescription] || (sportTree[category.rowDescription] = { l1: category.rowDescription, l2: {} }));
                    const region = (sport.l2[category.shortDescription] || (sport.l2[category.shortDescription] = { l2: category.shortDescription, leagues: [] }))
                    region.leagues.push(category);
                }
            }

            this.setState({
                leagues: categoriesData,
                loading: false,
                sportTree
            });
            this.toggleLeagueSelection(null, { idLeague: -999 });
        } else {
            if (response.status === 401) {
                this.props.logout();
            }
            this.setState({ loading: false });
        }
    }

    async populateFillOpenBets() {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `bearer ${this.props.token}`
            }
        };
        this.setState({ loading: true });
        const response = await fetch(`api/v1/players/0/fillopenbets`, requestOptions);
        if (response.ok) {
            const history = await response.json();
            for (var i = 0; i < history.length; i++) {
                const header = history[i];
                if (header.items && header.items.length) {
                    let openItems = 0;
                    for (var j = 0; j < header.items.length; j++) {
                        const item = header.items[j];
                        if (item.idGame === 0) {
                            openItems++;
                        }
                    }

                    header.openItems = openItems;
                }
            }
            this.setState({ history, loading: false });
        } else {
            if (response.status === 401) {
                this.props.logout();
            }
            this.setState({ loading: false });
        }
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.auth.token,
        info: state.auth,
        languageId: state.auth.languageId,
        cartItemMap: state.betslip.byId,
        cartItemCount: state.betslip.ids.length,
        games: state.betslip.games,
        status: state.betslip.status,
        betslipOpen: state.betslip.betslipOpen,
        sportMenuOpen: state.betslip.sportMenuOpen,
    }
}

const mapDispatchToProps = {
    logout: AuthActions.resetState,
    toggleItem: BetslipActions.toggleItem,
    toggleBetslip: BetslipActions.toggleBetslip,
    fetchGames: BetslipActions.fetchGames,
    fetchPersonalizedGames: BetslipActions.fetchPersonalizedGames,
    toggleSportMenu: BetslipActions.toggleSportMenu,
    sendBets: BetslipActions.sendBets
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PreMatch));