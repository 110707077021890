import Config from "../config/main";

export const formatHandicap = (value) => (value === 0) ? 'PK' : ((value > 0) ? '+' + value.toString() : value.toString());
export const formatTotal = (value, prefix) => (prefix || '') + value.toString();

const OddConverter = (function (Config) {
    const cache = {};
    const possibleFormats = ['decimal', 'fractional', 'american', 'hongkong', 'malay', 'indo'];

    function formatAmericanOdds(value){
        const wholeNumber = Math.round(value);

        if (wholeNumber === 100 || wholeNumber === -100)
            return 'EV';

        return (wholeNumber > 0) ? '+' + wholeNumber.toString() : wholeNumber.toString();
    }

    function convert(value, format) {

        //const fValue = parseFloat(value);
        //const iValue = parseInt(value, 10);
        const rValue = (value !== undefined && value !== '') ? Math.round(parseFloat(value) * 100 || 0) / 100 : value;

        switch (format) {
            case 'american':
                //return value ? rValue > 2 ? '+' + (100 * (rValue - 1)).toString().split('.')[0] : rValue !== 1 ? (-100 / (rValue - 1)).toString().split('.')[0] : '-' : rValue;
                return formatAmericanOdds(value);
            default:
                return rValue;
        }
    }

    return function (value, format, type = null, displayKey) {
        format = format || Config.env.oddFormat;
        if (value === null || value === undefined || isNaN(value)) {
            return value;
        }
        if (value === 1) {
            return null;
        }
        if (Config.main.specialOddFormat && Config.main.specialOddFormat[format]) {
            format = Config.main.specialOddFormat[format].displayKey[displayKey] || Config.main.specialOddFormat[format].default;
        }

        let cacheKey = (format || Config.env.oddFormat).concat(value);
        if (cache[cacheKey] === undefined) {
            if (possibleFormats.indexOf(format) === -1) { //select default format if current one is invalid
                format = possibleFormats[0];
            }
            if (format === 'fractional' && Config.main.useLadderForFractionalFormat && type === 'fictional' && value !== undefined) { // use it to calculate express odds as you see on bet365 :)
                cache[cacheKey] = Math.round(parseFloat(value - 1) * 100 || 0) / 100 + '/1';
            } else {
                cache[cacheKey] = convert(value, format);
            }
        }
        return cache[cacheKey];
    };

})(Config);

export default OddConverter;