const Config = {
    main: {
        availableLanguages: {
            eng: {
                short: 'EN',
                full: "English",
                order: 1
            }
        }
    },
    betting: {
        resetAmountAfterBet: false,
        totalOddsMax: 1000
    },
    env: {
        lang: "eng",
        oddFormat: "american",
        passwordOpt: 1,//0=none, 1=uppercase, 2=lowecase
    }
};


export default Config;