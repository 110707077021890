import { ConfigActionType } from './types';

const initialState = {
    appReady: false,
    title: '',
    company: '',
    settingsOpen: false,
    passwordOpt: 0,
    configurations: []
};

const ConfigReducer = (state = initialState, action) => {
    switch (action.type) {
        case ConfigActionType.SET_READY: {
            return {
                ...state,
                ...action.payload,
                appReady: true
            };
        }
        case ConfigActionType.SET_NOT_READY: {
            return {
                ...state,
                appReady: false
            };
        }
        case ConfigActionType.TOGGLE_SETTINGS: {
            return {
                ...state,
                settingsOpen: !state.settingsOpen
            };
        }
        default:
            return state;
    }
};

export default ConfigReducer;